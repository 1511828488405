import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  GoogleTagManagerService,
  ApplicationInsightsService,
  IdlingService,
} from "@crux/services";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  value: any;
  constructor(private _ai: ApplicationInsightsService,
    private _router: Router,
    private route: ActivatedRoute) {
    
  }
  ngOnInit() {
    var url =window.location.href;
    var params = url.split('?');
  
      if ( params && params[1]) {
          this.value =atob(params[1]);
          localStorage.setItem('userInfo',this.value);
          localStorage.setItem('isFromUnqork', '1');
      }
      else {
        localStorage.setItem('isFromUnqork', '0');
      }
  }
}
