import { createReducer, on } from '@ngrx/store';
import { reset, saveTokenDetails } from '../actions';


import { tokenInitialState } from '../initial-state';

const _tokenReducer = createReducer(
  tokenInitialState,
  on(saveTokenDetails, (state, { payload }) => ({ ...state, ...payload })),
  on(reset, (state) => ({}))
);

export function tokenReducer(state = tokenInitialState, action) {
  return _tokenReducer(state, action);
}
