import { createReducer, on } from '@ngrx/store';
import { 
    reset, 
    saveCommissionDetails 
} from '../actions/commission-structure.action';
import { commissionDetailsInitialState } from '../initial-state/commission-structure.state';


const _commissionDetailsReducer = createReducer(
    commissionDetailsInitialState,
    on(saveCommissionDetails, (state, { payload }) => ({ ...state, ...payload })),
    on(reset, (state) => ({}))
);

export function commissionDetailsReducer(state = commissionDetailsInitialState, action) {
    return _commissionDetailsReducer(state, action);
}