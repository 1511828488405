import { createReducer, on } from '@ngrx/store';
import { reset, saveContactIdDetails } from '../actions/contact-selected-details.action';

import { contactIdInitialState } from '../initial-state';

const _contactIdReducer = createReducer(
    contactIdInitialState,
  on(saveContactIdDetails, (state, { payload }) => ({ ...state, ...payload })),
  on(reset, (state) => ({}))
);

export function contactIdReducer(state = contactIdInitialState, action) {
  return _contactIdReducer(state, action);
}