import { Injectable } from '@angular/core';
import { CanActivate, Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable()
export class AppRedirectGuard implements CanActivate {
    private previousUrl: string = undefined;
    private currentUrl: string = undefined;
    draftMaId: any;
    draftRfpId: any;
    
    constructor(
        private _router: Router,
        private route: ActivatedRoute
    ) { }

    canActivate(): Observable<boolean> {
        this.route.queryParams.subscribe(queryParams => {
            this.draftMaId = queryParams['draftMaId'];
            this.draftRfpId = queryParams['draftRfpId'];
          });
        this.currentUrl = this._router.url;
        this._router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.previousUrl = this.currentUrl;
                this.currentUrl = event.url;
                if (this.previousUrl === "/") {
                    this._router.navigate(["dashboard"]);
                }
            }
        });
        return of(true);
    }
}