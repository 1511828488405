import { Injectable } from "@angular/core";
import { Router, NavigationEnd, NavigationStart } from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class RouterStateService {
  previousUrl: string;
  currentUrl: string;
  constructor(private _router: Router) {
    this.currentUrl = this._router.url;
    this._router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });
  }

  getCurrentRoute() {
    return this.currentUrl;
  }

  getPreviousRoute() {
    return this.previousUrl;
  }
}
