import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CommonDialogComponent } from '../widgets/shared/common-dialog/common-dialog.component';


@Injectable({
    providedIn: 'root',
})
export class CommonDialogService {
    dialogRef: any;
    constructor(public dialog: MatDialog) {
    }
    openCommonDialog(data) {
        this.dialogRef = this.dialog.open(CommonDialogComponent, {
            height: 'auto',
            data: data,
            disableClose: true,
            maxWidth: data.maxWidth,
            panelClass: data.panelClass
        });
    }
    closeCommonDialog() {
        if (this.dialogRef) {
            this.dialogRef.close();
        }
    }
}
