import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MsalService } from "@azure/msal-angular";
import { Action } from "rxjs/internal/scheduler/Action";

@Injectable()
export class Globals {
    user_name: any;
    name: any;
    unQorkFlag: any;
    userInformation: any;
    constructor(private authService: MsalService,
        private route: ActivatedRoute) {
    }
    getAccountDetails() {
        if(this.authService.getAccount() && localStorage.getItem('isFromUnqork') != '1') {
            return this.authService.getAccount();
        }
        else {
            this.userInformation = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : undefined;
            return {
                userName: this.userInformation && this.userInformation.email ? this.userInformation.email : undefined ,
                name : this.userInformation && this.userInformation.lastName ? this.userInformation.lastName : '' + " , " + this.userInformation && this.userInformation.firstName ? this.userInformation.firstName : '',
                roles: this.userInformation.roles ? this.userInformation.roles : undefined
            }
        }
    }
}