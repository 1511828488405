import { createReducer, on } from '@ngrx/store';
import {
    saveEmployerBilling,
    reset
} from '../actions/employer-billing.action';
import { employerBillingInitialState } from '../initial-state';


const _employerBillingReducer = createReducer(
    employerBillingInitialState,
    on(saveEmployerBilling, (state, { payload }) => ({ ...state, ...payload })),
    on(reset, (state) => ({}))
);

export function employerBillingReducer(state = employerBillingInitialState, action) {
    return _employerBillingReducer(state, action);
}