import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { MediaQueryService } from "@crux/services";
import { FlexLayoutModule } from "@angular/flex-layout";
import { PublicLayoutComponent } from "./public/public-layout.component";

@NgModule({
  imports: [CommonModule, FlexLayoutModule, RouterModule],
  declarations: [PublicLayoutComponent],
  providers: [MediaQueryService]
})
export class LayoutModule {}
