import { createReducer, on } from '@ngrx/store';
import {
    saveCurrentBenefits,
    reset
} from '../actions/current-benefits.action';
import { currentBenefitsInitialState } from '../initial-state';


const _currentBenefitsReducer = createReducer(
    currentBenefitsInitialState,
    on(saveCurrentBenefits, (state, { payload }) => ({ ...state, ...payload })),
    on(reset, (state) => ({}))
);

export function currentBenefitsReducer(state = currentBenefitsInitialState, action) {
    return _currentBenefitsReducer(state, action);
}