import { Routes } from '@angular/router';
import { PublicLayoutComponent } from './layout';
import { AppContextGuard } from './guards';
import { environment } from '../environments/environment';
import { MsalGuard } from '@azure/msal-angular';
import { LoginCallbackComponent } from './widgets/shared/login-callback/login-callback.component';
import { AppDashboardComponent } from './pages/app-dashboard/app-dashboard.component';
import { LoginUnqorkComponent } from './widgets/shared/login-Unqork/login-unqork.component';

export const appRoutes: Routes = [
  {
    path: '',
    component: PublicLayoutComponent,
    canActivate: [AppContextGuard],
    children: [
      {
        path: '',
        redirectTo: "login",
        pathMatch: "full"
      },
      {
        path: '',
        loadChildren: './pages/app-dashboard/app-dashboard.module#AppDashboardModule',
        data: {
          title: 'Dashboard'
        },
      },
      {
        path: "login/callback",
        redirectTo: 'login'
      },
      {
        path: "login",
        component: LoginCallbackComponent
      },
      {
        path: "login/unqork",
        component: LoginUnqorkComponent
      },
    ]
  }
  // ,
  // {
  //   path: environment.url.pageNotFound,
  //   canActivate: [],
  //   loadChildren:
  //     './components/page-not-found/page-not-found.module#PageNotFoundModule',
  //   data: {
  //     title: 'Page Not Found',
  //   }
  // }
];
