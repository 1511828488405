import { Injectable } from "@angular/core";
import {
    CanDeactivate
} from "@angular/router";

import { PreventBackButtonService } from '../services/prevent-back-button.service';

@Injectable()
export class BrowserBackGuard implements CanDeactivate<any> {
    constructor(private _backbuttonService: PreventBackButtonService) { }
    canDeactivate() {
        if (this._backbuttonService.getBackClicked()) {
            this._backbuttonService.setBackClicked(false);
            history.pushState(null, null, location.href);
            return false;
        }
        return true;
    }
}
