import { ActionTypes } from '../actions/clear-store.action';
export function clearStore(reducer) {
  return function(state, action) {
    if (action.type === ActionTypes.CLEARSTORE) {
      state = undefined;
    }

    return reducer(state, action);
  };
}
