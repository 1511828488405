import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppStepperService } from '../pages/application-steps/app-stepper.service';
import { environment } from '../../environments/environment';
import { forkJoin, Observable, of } from 'rxjs';
import { concatMap, map, switchMap, tap } from 'rxjs/operators';
import { getFetchRecordQueryForAdmin, getFetchRecordQueryForAgent, getFetchRecordQueryForManager, getJsonBody, queries, rfpgetFetchRecordQueryForAdmin, rfpgetFetchRecordQueryForAgent, rfpgetFetchRecordQueryForManager } from '../widgets/shared/apiqueries';
import { LoadingIndicatorService } from '.';
import { saveAccountIdDetails, saveContactDetails, saveHrContactDetails, saveSelectedCustomerDetails, saveTokenDetails } from '../state/actions';
import { Store } from '@ngrx/store';
import { AppContextService } from '../app.context.service';
import { trimEnd, includes, forEach, reduce, findKey, cloneDeep } from 'lodash-es';
import { AppRfpStepperService } from '../pages/rfp-application-steps/app-rfp-stepper.service';
import moment from 'moment-timezone';

@Injectable({
  providedIn: 'root',
})
export class FormService {

  accountId = "";
  dropdownData: any;
  dropdownDataRfp: any;

  customerTodoData: any;
  customerSubmittedData: any;
  customerDecisionedData: any;
  fileUploadOwnerId: any;
  subAgentsResp: any;
  subRespList: any;
  finalDashboardData: boolean = false;
  dropdownDataExt: any;
  payRollFrequecyData:any;

  constructor(private _http: HttpClient,
    private _appStepperService: AppStepperService,
    private _appRfpStepperService: AppRfpStepperService,
    private _loaderService: LoadingIndicatorService,
    private _appContext: AppContextService,
    private _store: Store<any>) {

  }

  getToken() {
    const url = environment.csiUrl.tokenAPI;
    const headers = new HttpHeaders({
      App_ID: environment.csiUrl.App_ID,
      App_Key: environment.csiUrl.App_Key,
      Resource: environment.csiUrl.Resource,
      apiVersion: environment.csiUrl.apiVersion
    });
    return this._http
      .post(url, { showLoader: true }, { headers: headers })
      .pipe(map((response: HttpResponse<any>) => response));
  }

  getAddressListNew(addressLine, instanceKey, countryCode, castingVal) {
    let url = environment.endPoints.appFormsEndpoints.addressSearchNew;
    const addPass = '5hvDJ`|1QB?Z1f+X84m#x5?z';
    const authData = window.btoa(
      environment.addressUserName + ':' + addPass
    );
    url =
      url +
      environment.addressLine1 +
      addressLine +
      environment.instanceKey +
      instanceKey +
      environment.country +
      countryCode +
      environment.castingKey +
      castingVal;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Basic ${authData}`,
      "Access-Control-Allow-Origin": environment.endPoints.appFormsEndpoints.allowOrigin
    });
    return this._http
      .get(url, { headers: headers })
      .pipe(map((response: HttpResponse<any>) => response));
  }

  getAddressUniqueId() {
    const url = environment.endPoints.appFormsEndpoints.addressUniqueId;
    const addPass = '5hvDJ`|1QB?Z1f+X84m#x5?z';
    const authData = window.btoa(
      environment.addressUserName + ':' + addPass
    );
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Basic ${authData}`,
      "Access-Control-Allow-Origin": environment.endPoints.appFormsEndpoints.allowOrigin,
    });
    return this._http
      .get(url, { headers: headers })
      .pipe(map((response: HttpResponse<any>) => response));
  }

  getCustomerDetails() {
    const mockURL = 'assets/mock/getCustomerDetailsNew.json';
    const headers = new HttpHeaders({
      skip: 'true',
    });
    return this._http
      .get(mockURL, { headers: headers })
      .pipe(map((response: Response) => response));
  }
  getRowData(draftMaId: string, httpMethod: string = 'GET'): Observable<any> {
    const fieldArr = queries.fetchMAInfo;
    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    // let body  = {
    //     HttpMethod: httpMethod,
    //     JsonBody: "",
    //     SubUri: "/services/data/v51.0/query/?q=SELECT+Master_Application_Status__c,Id,Master_App_Account__c+from+Master_Application__c+where+Name=" + draftMaId,
    //     enableLoader: true
    // }
    const body = {
      HttpMethod: httpMethod,
      JsonBody: "",
      SubUri: '/services/data/v51.0/query/?q=' + fieldArr + "'" + draftMaId.toString() + "'",
      enableLoader: true
    };
    return this._http.post(endpointUri, body).pipe(
      map((resp) => {
        return resp;
      })
    );
  }
  getRowDataForRFP (draftRfpId: string, httpMethod: string = 'GET'): Observable<any> {
    const fieldArr = queries.fetchRFPInfo;
    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    const body = {
      HttpMethod: httpMethod,
      JsonBody: "",
      SubUri: '/services/data/v51.0/query/?q=' + fieldArr + "'" + draftRfpId.toString() + "'",
      enableLoader: true
    };
    return this._http.post(endpointUri, body).pipe(
      map((resp) => {
        return resp;
      })
    );
  }
  getCustomerInfo(customerId: string, httpMethod: string = 'GET',loaderNotRequired?: any): Observable<any> {

    const fieldArr = queries.allfields;
    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    let body;
    if(loaderNotRequired){
      body = {
        HttpMethod: httpMethod,
        JsonBody: "",
        SubUri: "/services/data/v51.0/sobjects/Master_Application__c/" + customerId + "?fields=" + fieldArr.toString()
      }
    }else{
      body = {
        HttpMethod: httpMethod,
        JsonBody: "",
        SubUri: "/services/data/v51.0/sobjects/Master_Application__c/" + customerId + "?fields=" + fieldArr.toString(),
        enableLoader: true
      }
    }
    return this._http.post(endpointUri, body).pipe(
      map((resp) => {
        const data = {
          errors: [],
          id: resp["Id"],
          name: resp["Name"],
          success: true,
          accountId: resp["Master_App_Account__c"]

        }
        this._store.dispatch(saveAccountIdDetails({ payload: data }));
        return resp;
      })
    );
  }

  getCustomerInfoRfp(customerId: string, httpMethod: string = 'GET'): Observable<any> {

    const fieldArr = queries.allfieldsRfp;
    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    const body = {
      HttpMethod: httpMethod,
      JsonBody: "",
      SubUri: "/services/data/v51.0/sobjects/Request_for_Proposal__c/" + customerId + "?fields=" + fieldArr.toString(),
      enableLoader: true
    }
    return this._http.post(endpointUri, body).pipe(
      map((resp) => {
        const data = {
          errors: [],
          id: resp["Id"],
          name: resp["Name"],
          success: true,
          accountId: resp["RFP_Account__c"]
        }
        this._store.dispatch(saveAccountIdDetails({ payload: data }));
        return resp;
      })
    );
  }

  getCustomerRfpStatus(customerId: string, httpMethod: string = 'GET'): Observable<any> {

    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    const body = {
      HttpMethod: httpMethod,
      JsonBody: "",
      SubUri: "/services/data/v51.0/sobjects/Request_for_Proposal__c/" + customerId + "?fields=RFP_Status__c",
      enableLoader: true
    }
    return this._http.post(endpointUri, body).pipe(
      map((resp) => {

        return resp;
      })
    );
  }

  getCustomerStatus(customerId: string, httpMethod: string = 'GET'): Observable<any> {

    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    const body = {
      HttpMethod: httpMethod,
      JsonBody: "",
      SubUri: "/services/data/v51.0/sobjects/Master_Application__c/" + customerId + "?fields=Master_Application_Status__c",
      enableLoader: true
    }
    return this._http.post(endpointUri, body).pipe(
      map((resp) => {
        this._loaderService.closeAllLoadingIndicator();
        return resp;
      })
    );
  }

  getCustomerTodoData(httpMethod: string = 'GET'): Observable<any> {
    if (this.customerTodoData) {
      return of(this.customerTodoData);
    }
    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    const body = {
      HttpMethod: httpMethod,
      JsonBody: "",
      SubUri: "/services/data/v51.0/query/?q=" + queries.fetchDashboardDraftsData,
      enableLoader: true
    };
    // 
    return this._http.post(endpointUri, body).pipe(
      map((resp) => {
        // 
        this.customerTodoData = resp;
        return resp;
      })
    );
  }

  getCustomerSubmittedData(httpMethod: string = 'GET'): Observable<any> {
    if (this.customerSubmittedData) {
      return of(this.customerSubmittedData);
    }
    // 
    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    const body = {
      HttpMethod: httpMethod,
      JsonBody: "",
      SubUri: "/services/data/v51.0/query/?q=" + queries.fetchDashboardSubmittedData,
      enableLoader: true
    };
    return this._http.post(endpointUri, body).pipe(
      map((resp) => {
        // 
        this.customerSubmittedData = resp;
        return resp;
      })
    );
  }

  getCustomerDecisionedData(httpMethod: string = 'GET'): Observable<any> {
    if (this.customerDecisionedData) {
      return of(this.customerDecisionedData);
    }
    // 
    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    const body = {
      HttpMethod: httpMethod,
      JsonBody: "",
      SubUri: "/services/data/v51.0/query/?q=" + queries.fetchDashboardDecisionedData,
      enableLoader: true
    };
    return this._http.post(endpointUri, body).pipe(
      map((resp) => {
        // 
        this.customerDecisionedData = resp;
        return resp;
      })
    );
  }

  getCustomerAccountInfo(customerId: string, httpMethod: string = 'GET'): Observable<any> {

    const fieldArr = queries.allfields;
    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    const body = {
      HttpMethod: httpMethod,
      JsonBody: "",
      SubUri: "/services/data/v51.0/sobjects/Master_Application__c/" + customerId + "?fields=Master_App_Account__c",
      enableLoader: true
    }
    return this._http.post(endpointUri, body).pipe(
      map((resp) => {

        return resp;
      })
    );
  }
  getRfpCustomerAccountInfo(customerId: string, httpMethod: string = 'GET'): Observable<any> {
    
    const fieldArr = queries.allfields;
    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    const body = {
      HttpMethod: httpMethod,
      JsonBody: "",
      SubUri: "/services/data/v51.0/sobjects/Request_for_Proposal__c/" + customerId + "?fields=RFP_Account__c",
      enableLoader: true
    }
    return this._http.post(endpointUri, body).pipe(
      map((resp) => {
        
        return resp;
      })
    );
  }
  // saveformData(formData: any, httpMethod: string = 'POST', accountReq: any = {}, contacttReqData: any = {}): Observable<any> {

  //   const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
  //   const jsonBody = {
  //     JsonBody: JSON.stringify(accountReq),
  //     SubUri: environment.endPoints.appFormsEndpoints.accountSubUri,
  //     HttpMethod: 'POST'
  //   };

  //   return this._http.post(endpointUri, jsonBody).pipe(
  //     switchMap((data) => {
  //       this.accountId = data['id'];

  //       const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
  //       const accountId = this.accountId;
  //       contacttReqData.Payroll__c = true;
  //       contacttReqData.AccountId = accountId;
  //       const jsonBody = JSON.stringify(contacttReqData);
  //       const body = {
  //         HttpMethod: httpMethod,
  //         JsonBody: jsonBody,
  //         SubUri: environment.endPoints.appFormsEndpoints.contactSubUri
  //       };
  //       return this._http.post(endpointUri, body).pipe(
  //         switchMap((data) => {
  //           contacttReqData['Id'] = data['id'];
  //           this._store.dispatch(saveContactDetails({ payload: contacttReqData }));
  //           formData.Master_App_Account__c = this.accountId;
  //           const body = {
  //             HttpMethod: httpMethod,
  //             JsonBody: JSON.stringify(formData),
  //             SubUri:
  //               environment.endPoints.appFormsEndpoints.masterApplicationSubUri,
  //           };
  //           return this._http.post(endpointUri, body);
  //         })
  //       );
  //     })
  //   );
  // }

  saveformData(formData: any, httpMethod: string = 'POST', accountReq: any = {}, contacttReqData: any = {}, contactDataId?: any): Observable<any> {
    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    const jsonBody = {
      JsonBody: JSON.stringify(accountReq),
      SubUri: environment.endPoints.appFormsEndpoints.accountSubUri,
      HttpMethod: 'POST',
      enableLoader: true
    };
    if (!contactDataId) {
      return this._http.post(endpointUri, jsonBody).pipe(
        switchMap((data) => {
          this.accountId = data['id'];
          const resp: any = data;
          if((resp && !resp['Code'] && resp.success != true) || (resp['Code'] && resp['Code'] !== "0000")){
            const response = []
            response.push(data);
            return response;
          }
          const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
          const accountId = this.accountId;
          // contacttReqData.Payroll__c = true;
          contacttReqData.AccountId = accountId;
          const updateAddressToUppercase = contacttReqData.MailingStreet;
          contacttReqData.MailingStreet = updateAddressToUppercase?.toUpperCase();
          const updateCityToUppercase = contacttReqData.MailingCity;
          contacttReqData.MailingCity = updateCityToUppercase?.toUpperCase();
          const jsonBody = JSON.stringify(contacttReqData);
          const body = {
            HttpMethod: httpMethod,
            JsonBody: jsonBody,
            SubUri: environment.endPoints.appFormsEndpoints.contactSubUri,
            enableLoader: true
          };
          return this._http.post(endpointUri, body).pipe(
            switchMap((data) => {
              contacttReqData['Id'] = data['id'];
              this._store.dispatch(saveContactDetails({ payload: contacttReqData }));
              formData.Master_App_Account__c = this.accountId;
              formData.Bill_Call_Contact__c = data['id'];
              const body = {
                HttpMethod: httpMethod,
                JsonBody: JSON.stringify(formData),
                SubUri:
                  environment.endPoints.appFormsEndpoints.masterApplicationSubUri,
                enableLoader: true
              };
              return this._http.post(endpointUri, body).pipe(
                map((resp) => {
                  const response: any = resp;
                  const data = {
                    errors: [],
                    id: response.id,
                    name: response.Name,
                    success: true,
                    accountId: accountId
                  }
                  this._store.dispatch(saveAccountIdDetails({ payload: data }));
                  return resp;
                })
              );
            })
          );
        })
      );
    } else {
      return this._http.post(endpointUri, jsonBody).pipe(
        switchMap((data) => {
          this.accountId = data['id'];
          const resp: any = data;
          if((resp && !resp['Code'] && resp.success != true) || (resp['Code'] && resp['Code'] !== "0000")){
            const response = []
            response.push(data);
            return response;
          }
          const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
          if(formData.hasOwnProperty("Request_for_Proposal__c") && formData.hasOwnProperty("GCAP_Owner_Selected_Underwriting_Type__c") && formData['GCAP_Owner_Selected_Underwriting_Type__c'] == "Block Offer"){
            delete formData["Request_for_Proposal__c"]
          }
          formData.Master_App_Account__c = this.accountId;
          formData.Bill_Call_Contact__c = contactDataId;
          const body = {
            HttpMethod: httpMethod,
            JsonBody: JSON.stringify(formData),
            SubUri:
              environment.endPoints.appFormsEndpoints.masterApplicationSubUri,
            enableLoader: true
          };
          return this._http.post(endpointUri, body);
        })
      );
    }
  }

  getProductRestriction(accountReq: any,httpMethod:string,masterAppNumber:any): Observable<any> {
    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    let correctJson = {};
    let jsonBody:any;
    if(masterAppNumber === null){
      correctJson = {
        "fieldValues": accountReq,
        "fieldsToQuery": [],
        "returnRestrictions": true
      } 
    }else{
      correctJson = {
        "masterAppNumber":masterAppNumber,
        "fieldValues": accountReq,
        "fieldsToQuery": ["Minimum_Length_of_Service_days__c"],
        "returnRestrictions": true
      }
    } 
    if(httpMethod === "PATCH"){
        jsonBody = {
          JsonBody: JSON.stringify(correctJson),
          SubUri: "/services/apexrest/masterapplication/",
          HttpMethod: httpMethod,
          enableLoader: true
        };
    }else{
        jsonBody = {
          "JsonBody": "",
          "SubUri": "/services/apexrest/masterapplication?query=SELECT+Minimum_Length_of_Service_days__c,Underwriting_Offer_Sold__c+from+Master_Application__c+WHERE+Name='" + masterAppNumber + "'" ,
          "HttpMethod": 'GET',
          enableLoader: true
        }
    }
    
    return this._http.post(endpointUri, jsonBody);
  }

  saveContactFormData(formData: any, httpMethod: string = 'POST', accountId: string, payroll?: boolean) {
    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    formData.Payroll__c = payroll === true ? true : undefined;
    formData.AccountId = accountId;
    const updateAddressToUppercase = formData.MailingStreet;
    formData.MailingStreet = updateAddressToUppercase?.toUpperCase();
    const updateCityToUppercase = formData.MailingCity;
    formData.MailingCity = updateCityToUppercase?.toUpperCase();
    const jsonBody = JSON.stringify(formData);
    const body = {
      HttpMethod: httpMethod,
      JsonBody: jsonBody,
      SubUri: environment.endPoints.appFormsEndpoints.contactSubUri,
      enableLoader: true
    };
    return this._http.post(endpointUri, body);
  }

  saveReserviceStartNewData(formData: any, httpMethod: string = 'POST', accountReq: any = {}, contacttReqData: any = {}, accountDataId?: any): Observable<any> {

    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    accountReq['GCAP_Owner_Email__c'] = undefined;
    if(formData.hasOwnProperty("GCAP_Owner_Selected_Underwriting_Type__c")){
      delete formData["GCAP_Owner_Selected_Underwriting_Type__c"]
    }
    if(formData.hasOwnProperty("Underwriting_Offer_Sold__c")){
      delete formData["Underwriting_Offer_Sold__c"]
    }
    const jsonBody = {
      JsonBody: JSON.stringify(accountReq),
      SubUri: environment.endPoints.appFormsEndpoints.accountSubUri + accountDataId,
      HttpMethod: 'PATCH',
      enableLoader: true
    };
    return this._http.post(endpointUri, jsonBody).pipe(
      switchMap((data) => {
        this.accountId = data['id'];
        const accountId = this.accountId;
        // contacttReqData.Payroll__c = true;
        contacttReqData.AccountId = accountDataId;
        const contId = contacttReqData.Id;
        contacttReqData.Id = undefined;
        const updateAddressToUppercase = contacttReqData.MailingStreet;
        contacttReqData.MailingStreet = updateAddressToUppercase?.toUpperCase();
        const updateCityToUppercase = contacttReqData.MailingCity;
        contacttReqData.MailingCity = updateCityToUppercase?.toUpperCase();
        const jsonBody = JSON.stringify(contacttReqData);
        let body;
        if (contId) {
          body = {
            HttpMethod: 'PATCH',
            JsonBody: jsonBody,
            SubUri: environment.endPoints.appFormsEndpoints.contactSubUri + contId,
            enableLoader: true
          };
        } else {
          body = {
            HttpMethod: 'POST',
            JsonBody: jsonBody,
            SubUri: environment.endPoints.appFormsEndpoints.contactSubUri,
            enableLoader: true
          };
        }

        return this._http.post(endpointUri, body).pipe(
          switchMap((data) => {
            formData.Master_App_Account__c = accountDataId;
            formData["Bill_Call_Contact__c"] = contId ? contId : data["id"];
            formData['Application_Type__c'] = "Reservice";
            const body = {
              HttpMethod: 'POST',
              JsonBody: JSON.stringify(formData),
              SubUri:
                environment.endPoints.appFormsEndpoints.masterApplicationSubUri,
              enableLoader: true
            };
            return this._http.post(endpointUri, body).pipe(
              map((resp) => {
                const response: any = resp;
                const data = {
                  errors: [],
                  id: response.id,
                  success: true,
                  accountId: accountDataId
                }
                this._store.dispatch(saveAccountIdDetails({ payload: data }));
                const appType = {Application_Type__c: formData['Application_Type__c']}
                this._store.dispatch(saveSelectedCustomerDetails({ payload: appType }));
                return resp;
              })
            );
          })
        )
      })
    );

  }

  // Update MA status in Salesforce - Enrollment Completed
  // const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    // let formData = {};
    // formData['Master_Application_Status__c'] = 'Enrollment Completed';
    // const body = {
    //   HttpMethod: 'PATCH',
    //   JsonBody: JSON.stringify(formData),
    //   SubUri:
    //     environment.endPoints.appFormsEndpoints.masterApplicationSubUri + element["Id"],
    //   enableLoader: true
    // };
    // return this._http.post(endpointUri, body).pipe(
    //   switchMap((data) => {
    //     let extensionData = {};
    //     extensionData['Enrollment_Complete_User__c'] = loggedInUserName;
    //     extensionData['Enrollment_Complete_Date__c'] = moment().tz('America/New_York').format();
    //     extensionData['File_Transmission_Date__c'] = moment().tz('America/New_York').format();
    //     const body = {
    //       HttpMethod: 'PATCH',
    //       JsonBody: JSON.stringify(extensionData),
    //       SubUri: "/services/data/v51.0/sobjects/Master_Application_Extension__c/" + element?.Master_Application_Extension__r?.Id,
    //       enableLoader: true
    //     };
    //     return this._http.post(endpointUri, body).pipe(
    //       map((resp) => {
    //         return resp;
    //       })
    //     );
    //   })
    // )
  updateMaToEnrollmentCompletedStatus(element: any, httpMethod: string = 'PATCH', loggedInUserName): Observable<any> {
      //let SFmasterId = element.hasOwnProperty('Id') ?  element.Id : null;
      const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
      let extensionData = {};
      extensionData['Enrollment_Complete_User__c'] = loggedInUserName;
      extensionData['Enrollment_Complete_Date__c'] = moment().tz('America/New_York').format();
      const body = {
        HttpMethod: 'PATCH',
        JsonBody: JSON.stringify(extensionData),
        SubUri: "/services/data/v51.0/sobjects/Master_Application_Extension__c/" + element?.Master_Application_Extension__r?.Id,
        enableLoader: true
      };
      return this._http.post(endpointUri, body).pipe(
        map((resp) => {
          return resp;
        })
      );
      // return this._http.post(endpointUri, body).pipe(
      //   switchMap((data) => {
      //     let formData = {Id:SFmasterId};
      //     const body = {
      //       HttpMethod: 'POST',
      //       JsonBody: JSON.stringify(formData),
      //       SubUri: "/services/apexrest/selerixapi/filetransmission",
      //       enableLoader: true
      //     };
      //     return this._http.post(endpointUri, body).pipe(
      //       map((resp) => {
      //         return resp;
      //       })
      //     );
      //   })
      // )
  }

  updateMaStatus(element: any, httpMethod: string = 'PATCH'): Observable<any> {
    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    let formData = {};
    formData['Master_Application_Status__c'] = 'Open';
    formData['GCAP_App_Metadata__c'] = element.GCAP_App_Metadata__c;
    // formData["Bill_Call_Contact__c"] = contId ? contId : data["id"];
    // formData['Application_Type__c'] = "Reservice";
    const body = {
      HttpMethod: 'PATCH',
      JsonBody: JSON.stringify(formData),
      SubUri:
        environment.endPoints.appFormsEndpoints.masterApplicationSubUri + element["Id"],
      enableLoader: true
    };
    return this._http.post(endpointUri, body).pipe(
      map((resp) => {
        return resp;
      })
    );
  }

  updateRFPStatus(element: any, httpMethod: string = 'PATCH'): Observable<any> {
    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    let formData = {};
    formData['RFP_Status__c'] = 'Not Submitted';
    formData['GCAP_App_Metadata__c'] = element.GCAP_App_Metadata__c;
    const body = {
      HttpMethod: 'PATCH',
      JsonBody: JSON.stringify(formData),
      SubUri: environment.endPoints.appFormsEndpoints.rfpSubUri + element["Id"],
      enableLoader: true
    };
    return this._http.post(endpointUri, body).pipe(
      map((resp) => {
        return resp;
      })
    );
  }

  rfpSaveReserviceData(formData: any, httpMethod: string = 'POST', accountReq: any = {}, accountDataId?: any): Observable<any> {
    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    accountReq['GCAP_Owner_Email__c'] = undefined;
    const body = {
      JsonBody: JSON.stringify(accountReq),
      SubUri: environment.endPoints.appFormsEndpoints.accountSubUri + accountDataId,
      HttpMethod: 'PATCH',
      enableLoader: true
    };
    return this._http.post(endpointUri, body).pipe(
      switchMap((data) => {
        formData.RFP_Account__c = accountDataId;
        const body = {
          HttpMethod: 'POST',
          JsonBody: JSON.stringify(formData),
          SubUri:
            environment.endPoints.appFormsEndpoints.rfpSubUri,
          enableLoader: true
        };
        return this._http.post(endpointUri, body).pipe(
          map((resp) => {
            const response: any = resp;
            const data = {
              errors: [],
              id: response.id,
              success: true,
              accountId: accountDataId
            }
            this._store.dispatch(saveAccountIdDetails({ payload: data }));
            return resp;
          })
        );
      })
    )
  }

  getCustomerContactInfo(customerId: any, httpMethod: string = 'GET'): Observable<any> {
    const fieldArr = queries.allfields;
    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    const body = {
      "JsonBody": "",
      "HttpMethod": "GET",
      "SubUri": "/services/data/v51.0/sobjects/Contact/" + customerId + "?fields=Id,Email,Phone,FirstName,LastName,MailingCity,MailingState,MailingStreet,MailingPostalCode,AccountId",
      enableLoader: true
    };
    return this._http.post(endpointUri, body);
  }

  updateFormDataApexCall(accountId: any, formData: any, recordId: string, httpMethod: string = 'PATCH', accountName:string, contactId?: any): Observable<any> {
    formData.Master_App_Account__c = formData.Master_App_Account__c ? formData.Master_App_Account__c : accountId;
    formData.Bill_Call_Contact__c = contactId;
    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    const correctJson = {
      "masterAppNumber":accountName,
      "fieldValues": formData,
      "fieldsToQuery": [],
      "returnRestrictions": false
    }
    const self = this;
    self._loaderService.openLoadingIndicator({});
    const body = {
      JsonBody: JSON.stringify(correctJson),
      SubUri: "/services/apexrest/masterapplication/",
      HttpMethod: httpMethod,
      enableLoader: true
    };
    return this._http.post(endpointUri, body);
  }

  updateformData(accountId: any, formData: any, recordId: string, httpMethod: string = 'PATCH', contactId?: any): Observable<any> {
    formData.Master_App_Account__c = formData.Master_App_Account__c ? formData.Master_App_Account__c : accountId;
    formData.Bill_Call_Contact__c = contactId;
    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    const jsonBody = JSON.stringify(formData);
    const self = this;
    self._loaderService.openLoadingIndicator({});
    // setTimeout(() => {
    //   self._loaderService.openLoadingIndicator({});
    // });

    const body = {
      HttpMethod: httpMethod,
      JsonBody: jsonBody,
      SubUri: environment.endPoints.appFormsEndpoints.masterApplicationSubUri + recordId,
      // enableLoader: true
    };
    return this._http.post(endpointUri, body);
  }

  getCustomerHrContactInfo(accountId: any, contactIdVal?: any, httpMethod?: string) {
    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    const body = {
      "JsonBody": "",
      "HttpMethod": "GET",
      "SubUri": "/services/data/v51.0/query/?q=SELECT+id+from+Contact+where+AccountId='" + accountId + "'",
      enableLoader: true
    }
    return this._http.post(endpointUri, body).pipe(
      switchMap((data) => {
        const dataFromContact: any = data;
        let ContactId = [];
        let contactDetails: any;
        dataFromContact.records.forEach((element, index) => {
          ContactId.push("'" + element.Id + "'");
        });
        // ContactId.toString();
        contactDetails = ContactId.join(",")
        if (contactDetails) {
          const body = {
            "JsonBody": "",
            "HttpMethod": "GET",
            "SubUri": "/services/data/v51.0/query/?q=SELECT+Id,Payroll__c,Email,Phone,FirstName,LastName,MailingCity,MailingState,MailingStreet,MailingPostalCode,AccountId+from+Contact+where+Id+IN+(" + contactDetails + ")+and+Payroll__c=true",
            enableLoader: true
          };
          return this._http.post(endpointUri, body);
        } else {
          return null;
        }
      })
    );
  }

//invoke Selerix API to fetch plan unique ids
fetchPlanUniqueID(caseId, httpMethod: string = 'GET'): Observable<any> {
  const endpointUri = environment.endPoints.appFormsEndpoints.selerixEndpointUrl;
  const body = {
    Headers: [],
    HttpMethod: httpMethod,
    JsonBody: null,
    // SubUri: environment.endPoints.appFormsEndpoints.selerixParticipationCountGetSubUri
    // 4e751b4f-9687-4fc3-b096-33a083fa0233
    SubUri: "/api/setup/cases/" + caseId + "/plans?searchString=&maxResults=&startRecord=&sortBy=PlanSeqNumber&sortDirection=Ascending",
    enableLoader: true
  };
  return this._http.post(endpointUri, body);
  // Enable for mock
  // const mockURL = 'assets/mock/EnrollCompletedCall1Resp.json';
  //  const headers = new HttpHeaders({
  //    skip: 'true',
  //  });
  //  return this._http
  //    .get(mockURL, { headers: headers })
  //    .pipe(map((response: Response) => response));
}

fetchParticipationCountCall2(planUniqueIDs: any, caseUniqueId, httpMethod: string = 'GET'): Observable<any> {
  const endpointUri = environment.endPoints.appFormsEndpoints.selerixEndpointUrl;
  const body = {
    Headers: [],
    HttpMethod: httpMethod,
    JsonBody: null,
    // SubUri: environment.endPoints.appFormsEndpoints.selerixParticipationCountGetSubUri
    SubUri: '/api/hr/cases/' + caseUniqueId + '/plans/' + planUniqueIDs + '/employees/enrolledTotal',
    enableLoader: true
  };
  return this._http.post(endpointUri, body);
}
  // Update notes - request implementation
  updateformDataNotes(formData: any, recordId: string, httpMethod: string = 'PATCH'): Observable<any> {
    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    const jsonBody = JSON.stringify(formData);
    const body = {
      HttpMethod: httpMethod,
      JsonBody: jsonBody,
      SubUri: environment.endPoints.appFormsEndpoints.masterApplicationSubUri + recordId,
      enableMultiLoader: true
    };
    return this._http.post(endpointUri, body);
  }
  // Update notes - request implementation
  selerixGetApi(httpMethod: string = 'GET',templateType?:number): Observable<any> {
      const endpointUri = environment.endPoints.appFormsEndpoints.selerixEndpointUrl;
      const body = {
        Headers: [],
        HttpMethod: httpMethod,
        JsonBody: null,
        SubUri: templateType === 1 ? environment.endPoints.appFormsEndpoints.selerixGetSubUriTemplate1 
        : templateType === 2 ? environment.endPoints.appFormsEndpoints.selerixGetSubUriTemplate2 
        : templateType === 3 ? environment.endPoints.appFormsEndpoints.selerixGetSubUriTemplate3
        : environment.endPoints.appFormsEndpoints.selerixGetSubUri
      };
      return this._http.post(endpointUri, body);
  }
  // Selerix POST API for update values
  selerixPostApi(formData: any, httpMethod: string = 'POST',templateType?:number): Observable<any> {
    const endpointUri = environment.endPoints.appFormsEndpoints.selerixEndpointUrl;
    const body = {
      Headers: [],
      HttpMethod: httpMethod,
      JsonBody: JSON.stringify(formData),
      SubUri: templateType === 1 ? environment.endPoints.appFormsEndpoints.selerixGetSubUriTemplate1 
        : templateType === 2 ? environment.endPoints.appFormsEndpoints.selerixGetSubUriTemplate2 
        : templateType === 3 ? environment.endPoints.appFormsEndpoints.selerixGetSubUriTemplate3
        : environment.endPoints.appFormsEndpoints.selerixGetSubUri
    };
    return this._http.post(endpointUri, body);
  }

  // Selerix Enroller API get user ids
  selerixEnrollerGetApi(emailId: any, employerUniqueId: any, httpMethod: string = 'GET'): Observable<any> {
    const endpointUri = environment.endPoints.appFormsEndpoints.selerixEndpointUrl;
      const body = {
        Headers: [],
        HttpMethod: httpMethod,
        JsonBody: null,
        SubUri: environment.endPoints.appFormsEndpoints.selerixGetAgentUri + emailId
      };
      return this._http.post(endpointUri, body).pipe(
        switchMap((data) => {
          let responseData: any = data;
          responseData = JSON.parse(responseData);
          if (responseData.users && responseData.users[0].uniqueid) { 
            const jsonBody = {
              "uniqueid" : employerUniqueId.employer.uniqueid
            }
            const body = {
              "JsonBody": JSON.stringify(jsonBody),
              "HttpMethod": "POST",
              "SubUri": "/api/setup/users/" + responseData.users[0].uniqueid + "/associations/employers",
            };
            return this._http.post(endpointUri, body);
          } else {
            return of(null)
          }
        })
      );
  }
  // Get selerix history data
  getSelerixHistory(maID: any, httpMethod: string = 'GET'): Observable<any> {
    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    const body = {
      JsonBody: "",
      HttpMethod: httpMethod,
      SubUri: environment.endPoints.appFormsEndpoints.getSelerixHistoryUri + maID + "'"
    }
    return this._http.post(endpointUri, body);
  }
   
  //Fetch EDI Locations
  fetchedLocationUniqueId: any;
  productsInfoFetched: any;
  fetchLocation(caseId,rowData, httpMethod: string = 'GET'): Observable<any> {
    const endpointUri = environment.endPoints.appFormsEndpoints.selerixEndpointUrl;
    const body = {
      Headers: [],
      HttpMethod: 'GET',
      JsonBody: null,
      // SubUri: '/api/setup/cases/' + "2424b357-b6ca-4adc-b768-8adfac85f1e8" + '/locations?searchString=&maxResults=&startRecord=&sortBy=Name&sortDirection=Ascending'
      SubUri: '/api/setup/cases/' + caseId + '/locations?searchString=&maxResults=&startRecord=&sortBy=Name&sortDirection=Ascending'

    };
    // return this._http.post(endpointUri, body);
    return this._http.post(endpointUri, body).pipe(
      switchMap((data) => {
        let responseData: any = data;
        this.fetchedLocationUniqueId = JSON.parse(responseData).locations[0].uniqueid;
        const endpointUri = environment.endPoints.appFormsEndpoints.selerixEndpointUrl;
        const body = {
          Headers: [],
          HttpMethod: "GET",
          JsonBody: null,
          SubUri: '/api/setup/cases/' + caseId + '/products?searchString=&maxResults=0&startRecord=0&sortBy=Name&sortDirection=Ascending'
        };

          // return this._http.post(endpointUri, body).pipe(
          //   switchMap((productData) => {
          //     let responseProductData: any = productData;
          //     productsInfoFetched = JSON.parse(responseProductData);
          //     getMasterGroupNumbers(caseId,rowData)
          //   })
          // )

          return this._http.post(endpointUri, body).pipe(
            map((resp) => {
              let responseProductData: any = resp;
              this.productsInfoFetched = JSON.parse(responseProductData);
              return resp;
            })
          );

      })
    );
  }
  
    //Fetch Products associated to MA
    fetchProducts(caseId, httpMethod: string = 'GET'): Observable<any> {
      const endpointUri = environment.endPoints.appFormsEndpoints.selerixEndpointUrl;
      const body = {
        Headers: [],
        HttpMethod: httpMethod,
        JsonBody: null,
        SubUri: '/api/setup/cases/' + "2424b357-b6ca-4adc-b768-8adfac85f1e8" + '/products?searchString=&maxResults=0&startRecord=0&sortBy=Name&sortDirection=Ascending'
      };
      return this._http.post(endpointUri, body);
    }

    getMasterGroupNumbers(caseId, httpMethod: string = 'GET'): Observable<any> {
      
      
      const endpointUri = environment.endPoints.appFormsEndpoints.selerixEndpointUrl;
      const body = {
        Headers: [],
        HttpMethod: httpMethod,
        JsonBody: null,
        SubUri: '/api/setup/cases/'+ caseId + '/edi/locations/' + this.fetchedLocationUniqueId + '/planYears/default/group-numbers?updateAllLocations=true'
      };
      return this._http.post(endpointUri, body);
    }

    postMasterGroupNumbers(grupNumbers, caseId) {
      const endpointUri = environment.endPoints.appFormsEndpoints.selerixEndpointUrl;
      const jsonBody = {
        JsonBody: JSON.stringify(grupNumbers),
        SubUri: '/api/setup/cases/' + caseId + '/edi/locations/' + this.fetchedLocationUniqueId + '/planYears/default/group-numbers?updateAllLocations=true',
        HttpMethod: 'PATCH',
        enableLoader: true
      };
      return this._http.post(endpointUri, jsonBody)
    }



  // Create Contact Form Data
  createHrContactformData(formData: any, masterAppId: any, contacttReqData: any = {}, httpMethod: string = 'POST', accId?: any, GcapAppMetadataValue?: any, accountName?:string): Observable<any> {

    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    contacttReqData.Payroll__c = true;
    contacttReqData.AccountId = accId;
    const updateAddressToUppercase = contacttReqData.MailingStreet;
    contacttReqData.MailingStreet = updateAddressToUppercase?.toUpperCase();
    const updateCityToUppercase = contacttReqData.MailingCity;
    contacttReqData.MailingCity = updateCityToUppercase?.toUpperCase();
    const jsonBody = {
      JsonBody: JSON.stringify(contacttReqData),
      SubUri: environment.endPoints.appFormsEndpoints.contactSubUri,
      HttpMethod: 'POST',
      enableLoader: true
    };
    return this._http.post(endpointUri, jsonBody).pipe(
      switchMap((data) => {
        const accountId = data['id'];
        const id = {
          Id: accountId
        }
        this._store.dispatch(saveHrContactDetails({ payload: id }));
        // formData.HR_Payroll_Contact__c = accountId;
        formData.GCAP_app_metadata__c = GcapAppMetadataValue;
        const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
        //const jsonBody = JSON.stringify(formData);
        // const body = {
        //   HttpMethod: 'PATCH',
        //   JsonBody: jsonBody,
        //   SubUri: environment.endPoints.appFormsEndpoints.masterApplicationSubUri + masterAppId,
        //   enableLoader: true
        // };
        //formData.Master_App_Account__c = formData.Master_App_Account__c ? formData.Master_App_Account__c : masterAppId;
        if(formData.hasOwnProperty('Master_App_Account__c')){
          delete formData.Master_App_Account__c;
        }
        const correctJson = {
          "masterAppNumber":accountName,
          "fieldValues": formData,
          "fieldsToQuery": [],
          "returnRestrictions": false
        }
        const body = {
          JsonBody: JSON.stringify(correctJson),
          SubUri: "/services/apexrest/masterapplication/",
          HttpMethod: 'PATCH'
        };
        return this._http.post(endpointUri, body)
        // .pipe(
        //   map((resp) => {
        //     const response:any = resp;
        //     const data = {
        //       errors: [],
        //       id: response.Id,
        //       success: true,
        //       // accountId: 
        //       accountId: accId
        //     }
        //     // this._store.dispatch(saveAccountIdDetails({ payload: data }));
        //     return resp;
        //   })
        // );
      })
    );
  }

  // Update Contact Form Data
  updateContactformData(formData: any, recordId: string, httpMethod: string = 'PATCH', mappingNewKey: any, contactId: any, payroll?: boolean): Observable<any> {
    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    const accountId = mappingNewKey.Master_App_Account__c;
    formData.Payroll__c = payroll === true ? true : undefined;
    formData.AccountId = accountId;
    if(formData.MailingStreet){
      const updateAddressToUppercase = typeof formData.MailingStreet === "object" ? formData.MailingStreet.addressLine : formData.MailingStreet;
      formData.MailingStreet = updateAddressToUppercase?.toUpperCase();
    }
    if(formData.MailingCity){
      const updateCityToUppercase = formData.MailingCity;
      formData.MailingCity = updateCityToUppercase?.toUpperCase();
    }
    const jsonBody = JSON.stringify(formData);
    const body = {
      HttpMethod: httpMethod,
      JsonBody: jsonBody,
      SubUri: environment.endPoints.appFormsEndpoints.contactSubUri + contactId,
      enableLoader: true
    };
    return this._http.post(endpointUri, body);
  }

  updateAccountDetails(accountReq: any = {}, accountId) {
    this.accountId = accountId;
    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    const jsonBody = {
      JsonBody: JSON.stringify(accountReq),
      SubUri: environment.endPoints.appFormsEndpoints.accountSubUri + accountId,
      HttpMethod: 'PATCH',
      enableLoader: true
    };
    return this._http.post(endpointUri, jsonBody)
  }

  getTransformationObject(jsonUrl: string): Observable<any> {
    const mockURL = 'assets/json/' + jsonUrl + '.json';
    const headers = new HttpHeaders({
      skip: 'true',
    });
    return this._http
      .get(mockURL, { headers: headers })
      .pipe(map((response: Response) => response));
  }

  getEmailToken() { 
    const url = environment.SendEmailUrl.tokenAPI;
    const headers = new HttpHeaders({
      App_ID: environment.SendEmailUrl.App_ID,
      App_Key: environment.SendEmailUrl.App_Key,
      Resource: environment.SendEmailUrl.Resource,
      apiVersion: environment.SendEmailUrl.apiVersion
    });
    return this._http
      .post(url, { showLoader: true }, { headers: headers })
      .pipe(map((response: HttpResponse<any>) => response));
  }

  sendEmail(payload,tokenData) {
    this._store.dispatch(saveTokenDetails({ payload: { tokenData } }));
    const url = environment.endPoints.appFormsEndpoints.emailAPIURL;
    payload["APIKey"] = environment.emailTemplateAPIKey;
    payload["Identifier"] = environment.emailTemplateIdentifier;
    payload["FromId"] = environment.emaiTemplateFromId;
    payload["BCCList"] = environment.BCCList;
    payload["Channel"] = environment.channel;
    payload["ToList"] = environment.ToList;

    const headers = new HttpHeaders({
      'Authorization' : tokenData,
      'Content-Type' : `application/json`
    });
    return this._http
      .post(url,payload, { headers: headers })
      .pipe(map((response: HttpResponse<any>) => response));

}
        

sendEmailOfMsg(payload, fromMailID, toMailID,tokenData) {
  const url = environment.endPoints.appFormsEndpoints.emailAPIURL;
        payload["APIKey"] = environment.emailTemplateAPIKey;
        payload["Identifier"] = environment.emailTemplateIdentifier;
        payload["FromId"] = fromMailID;
        payload["BCCList"] = environment.BCCList;
        payload["Channel"] = environment.channel;
        payload["ToList"] = toMailID;
        console.log('bearerEmailToken---',tokenData)
        this._store.dispatch(saveTokenDetails({ payload: { tokenData } }));
        const headers = new HttpHeaders({
          'Authorization' : tokenData,
          'Content-Type' : `application/json`
        });
        return this._http
          .post(url,payload, { headers: headers })
          .pipe(map((response: HttpResponse<any>) => response));
}

  // getSelerixObject() {
  //   // const mockURL = 'assets/json/' + jsonUrl + '.json';
  //   // const headers = new HttpHeaders({
  //   //   skip: 'true',
  //   // });
  //   // return this._http
  //   //   .get(mockURL, { headers: headers })
  //   //   .pipe(map((response: Response) => response));

  //   const headers = new HttpHeaders({
  //     skip: 'true',
  //   });
  //   const endpointUri = environment.endPoints.appFormsEndpoints.selerixEndpointUrl;
  //   const jsonBody = {
  //     JsonBody: null,
  //     SubUri: '/api/setup/cases/' + environment.selerixGuid + '/copy',
  //     HttpMethod: 'GET',
  //     enableLoader: true
  //   };
  //   return this._http.post(endpointUri, jsonBody)
  // }

  getPickListData(): Observable<any> {
    const requestUrl = environment.endPoints.pickListDataEndpoint;
    // const requestUrl = "assets/mock/data.json";
    if (this.dropdownData) {
      return of(this.dropdownData);
    } else {
      return this._http.get(requestUrl).pipe(map(resp => {
        this._appStepperService.setDropdownData(resp);
        this.dropdownData = resp;
        return resp;
      }))
    }
  }

  getExpeditedPickListData(): Observable<any> {
    const requestUrl = environment.endPoints.pickListDataEndpoint;
      return this._http.get(requestUrl)
  }

  getPickListDataRfp(): Observable<any> {
    const requestUrl = environment.endPoints.pickListDataEndpointRfp;
    // const requestUrl = "assets/mock/data.json";
    if (this.dropdownDataRfp) {
      return of(this.dropdownDataRfp);
    } else {
      return this._http.get(requestUrl).pipe(
        map((resp) => {
          this._appRfpStepperService.setDropdownData(resp);
          this.dropdownDataRfp = resp;
          return resp;
        })
      );
    }
  }
  
  getPickListDataExt(): Observable<any> {
    const requestUrl = environment.endPoints.pickListDataExtensionEndpoint;
    // const requestUrl = "assets/mock/data.json";
    if (this.dropdownDataExt) {
      return of(this.dropdownDataExt);
    } else {
      return this._http.get(requestUrl).pipe(map(resp => {
        this._appStepperService.setExtDropdownData(resp);
        this.dropdownDataExt = resp;
        return resp;
      }))
    }
  }

  getPayrollFrequencyItemRfp(): Observable<any>{
    if(this.payRollFrequecyData){
      return of(this.payRollFrequecyData);
    }else{
      const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
      const SubUri = environment.endPoints.appFormsEndpoints.payrollFrequencySubUri;
      const body = {
        HttpMethod: "GET",
        JsonBody: "",
        SubUri
      }
      return this._http.post(endpointUri, body).pipe(map((resp) => {
        this._appRfpStepperService.setPayrollFrequencyData(resp);
        this.payRollFrequecyData = resp;
        return resp;
      }));
    }
  }

  getPayrollFrequencyItem(): Observable<any>{
    if(this.payRollFrequecyData){
      return of(this.payRollFrequecyData);
    }else{
      const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
      const SubUri = environment.endPoints.appFormsEndpoints.payrollFrequencySubUri;
      const body = {
        HttpMethod: "GET",
        JsonBody: "",
        SubUri
      }
      return this._http.post(endpointUri, body).pipe(map((resp) => {
        this._appStepperService.setPayrollFrequencyData(resp);
        this.payRollFrequecyData = resp;
        return resp;
      }));
    }
  }

  getAgentDetails(userMailId: string): Observable<any> {
    // TODO - Remove harcoded value
    const agentId = userMailId;
    // const agentId = 'agent11@combinedonline.com';
    const URL = environment.endPoints.agentDetails + agentId;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(URL, { headers: headers }).pipe(
      map((resp) => {
        return resp;
      })
    );
    // uncomment to Enable Mock
   // const mockURL = 'assets/mock/AgentsAPI.json';
   // const headers = new HttpHeaders({
    //  skip: 'true',
   // });
   // return this._http
     // .get(mockURL, { headers: headers })
     // .pipe(map((response: Response) => response));
  }

  getMasterAppCountData(): Observable<any> {
    // End point url
    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    // For Approved Master App count
    const countMAURL = "/services/data/v51.0/query/?q=" + queries.approvedMasterAppCount;
    const jsonBodyCountMa = {
      JsonBody: '',
      SubUri: countMAURL,
      HttpMethod: 'GET',
      enableLoader: true
    };
    let maCount = this._http.post(endpointUri, jsonBodyCountMa);

    return forkJoin([maCount]).pipe(
      map((resp) => {
        return resp;
      })
    );
  }

  getActiveCountData(): Observable<any> {
    // End point url
    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    // For Active Cases count
    const countActiveCasesURL = "/services/data/v51.0/query/?q=" + queries.activeCasesCount;
    const jsonBodyCountActiveCases = {
      JsonBody: '',
      SubUri: countActiveCasesURL,
      HttpMethod: 'GET',
      enableLoader: true
    };

    let activeCount = this._http.post(endpointUri, jsonBodyCountActiveCases)

    return forkJoin([activeCount]).pipe(
      map((resp) => {
        return resp;
      })
    );
  }

  // getAgentAndCountData(): Observable<any> {
  //   // For Agent details
  //   const URL = environment.endPoints.agentDetails;
  //   const headers = new HttpHeaders().set('Content-Type', 'application/json');
  //   // End point url
  //   const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
  //   // For Approved Master App count
  //   const countMAURL = "/services/data/v51.0/query/?q=" + queries.approvedMasterAppCount;
  //   const jsonBodyCountMa = {
  //     JsonBody: '',
  //     SubUri: countMAURL,
  //     HttpMethod: 'GET'
  //   };
  //   // For Active Cases count
  //   const countActiveCasesURL = "/services/data/v51.0/query/?q=" + queries.activeCasesCount;
  //   const jsonBodyCountActiveCases = {
  //     JsonBody: '',
  //     SubUri: countActiveCasesURL,
  //     HttpMethod: 'GET'
  //   };

  //   let agentDetails = this._http.get(URL, { headers: headers });
  //   let maCount = this._http.post(endpointUri, jsonBodyCountMa);
  //   let activeCount = this._http.post(endpointUri, jsonBodyCountActiveCases)

  //   return forkJoin([agentDetails, maCount, activeCount]).pipe(
  //     map((resp) => {
  //       return resp;
  //     })
  //   );
  // }

  removeCustomer(customerId: string, customerData: any, httpMethod: string = 'DELETE') {

    let body: any;
    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    if (customerData.Name.includes('RFP')) {
      body = {
        HttpMethod: httpMethod,
        JsonBody: "",
        SubUri: "/services/data/v51.0/sobjects/Request_for_Proposal__c/" + customerId,
        enableLoader: true
      }
    } else {
      body = {
        HttpMethod: httpMethod,
        JsonBody: "",
        SubUri: "/services/data/v51.0/sobjects/Master_Application__c/" + customerId,
        enableLoader: true
      }
    }
    return this._http.post(endpointUri, body).pipe(
      map((resp) => {
        this.customerTodoData = undefined;

        return resp;
      })
    );
  }

  uploadFile(file: any, filename?: any): Observable<any> {

    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    this.fileUploadOwnerId = this._appContext.get('pages.fileUploadOnwerId');
    const ownerId = this.fileUploadOwnerId.ownerId;
    // const ownerId = "0053j00000ASgKQ";

    const fileContent = file.base64Files.AttachmentList[0].attachment.fileContent;
    const fileName = file.base64Files.AttachmentList[0].file.name;
    const Title = JSON.stringify(filename);
    const ContentLocation = "S"
    const jsonbody = {
      Title: fileName,
      PathOnClient: Title,
      ContentLocation: ContentLocation,
      OwnerId: ownerId,
      VersionData: fileContent
    }
    // const fileContent = file.base64Files.AttachmentList[0].attachment.fileContent;
    // const fileName = filename;
    // const Title = filename.component ? filename.component : fileName.split('.').slice(0, -1).join('.');
    // const ContentLocation = "S"
    // const jsonbody = {
    //   Title: Title,
    //   PathOnClient: JSON.stringify(fileName),
    //   ContentLocation: ContentLocation,
    //   OwnerId: ownerId,
    //   VersionData: fileContent
    // }
    const body = {
      Headers: [],
      HttpMethod: "POST",
      JsonBody: JSON.stringify(jsonbody),
      SubUri: "/services/data/v51.0/sobjects/ContentVersion/",
      enableLoader: true
    }

    return this._http.post(endpointUri, body).pipe(
      map((resp) => {
        return resp;
      })
    );
  }

  saveUploadFile(file, MasterAppid): Observable<any> {
    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;

    const contentBody = {
      Headers: [],
      HttpMethod: "GET",
      JsonBody: "",
      SubUri: "/services/data/v51.0/query?q=SELECT+ContentDocumentId+FROM+ContentVersion+WHERE+Id+=+'" + file.id + "'",
      enableLoader: true
    }

    return this._http.post(endpointUri, contentBody).pipe(
      switchMap((data) => {
        if (data[0] && data[0].errorCode) {

        }
        const id = data["records"][0]["ContentDocumentId"];

        const jsonbody = {
          ContentDocumentId: id,
          LinkedEntityId: MasterAppid,
          Visibility: "AllUsers"
        }
        const body = {
          Headers: [],
          HttpMethod: "POST",
          JsonBody: JSON.stringify(jsonbody),
          SubUri: "/services/data/v51.0/sobjects/ContentDocumentLink",
          enableLoader: true
        };
        return this._http.post(endpointUri, body);
      })
    );
  }

  getFilesnfo(customerId: string, httpMethod: string = 'GET'): Observable<any> {
    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;

    const contentBody = {
      Headers: [],
      HttpMethod: "GET",
      JsonBody: "",
      SubUri: "/services/data/v51.0/query?q=SELECT+ContentDocumentId+FROM+ContentDocumentLink+WHERE+LinkedEntityId+=+'" + customerId + "'",
      enableLoader: true
    }

    return this._http.post(endpointUri, contentBody).pipe(
      switchMap((data) => {
        const filedata: any = data;
        const contentDocumentIdList = [];
        let listContentDocumentIds: any;
        try {
          filedata.records.forEach(element => {
            contentDocumentIdList.push("'" + element.ContentDocumentId + "'");
          });
        } catch (err) { }
        if (!contentDocumentIdList.length) {
          return of(null);
        }
        listContentDocumentIds = contentDocumentIdList.join(",")
        const body = {
          Headers: [],
          HttpMethod: "GET",
          JsonBody: "",
          SubUri: "/services/data/v51.0/query?q=SELECT+Title,PathOnClient,ContentLocation,VersionData+FROM+ContentVersion+WHERE+ContentDocumentId+IN+(" + listContentDocumentIds + ")",
          enableLoader: true
        };
        return this._http.post(endpointUri, body);
      })
    );
  }

  removeFile(fileUrl: string, httpMethod: string = 'DELETE', LinkedEntityId: any): Observable<any> {
    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;

    const contentBody = {
      Headers: [],
      HttpMethod: "GET",
      JsonBody: "",
      SubUri: fileUrl,
      enableLoader: true
    }

    return this._http.post(endpointUri, contentBody).pipe(
      switchMap((data: any) => {
        const body = {
          Headers: [],
          HttpMethod: "GET",
          JsonBody: "",
          SubUri: "/services/data/v51.0/query?q=SELECT+ContentDocumentId+FROM+ContentDocumentLink+WHERE+ContentDocumentId+=+'" + data.ContentDocumentId + "'+and+LinkedEntityId+=+'" + LinkedEntityId + "'",
          enableLoader: true
        };
        return this._http.post(endpointUri, body).pipe(
          switchMap((data: any) => {
            const body = {
              Headers: [],
              HttpMethod: "DELETE",
              JsonBody: "",
              SubUri: data.records[0].attributes.url,
              enableLoader: true
            };
            return this._http.post(endpointUri, body);
          })
        );
      })
    );
  }

  getActivityData(customerId: string, httpMethod: string = 'GET'): Observable<any> {
    const fieldArr = queries.allfields;
    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    const body = {
      HttpMethod: httpMethod,
      JsonBody: "",
      SubUri: "/services/data/v51.0/query/?q=SELECT+CreatedDate,LastModifiedDate,Approval_Request_Submitted_del__c,Pended_by_Group_Underwriting_Picklist__c,Group_UW_Pend_Date__c,Pended_Comments__c,Status_Last_Changed__c,Master_Application_Status__c,Application_Declined_Date__c,Application_Approval_Date__c,Billing_Call_Status__c+from+Master_Application__c+where+id='" + customerId + "'",
      enableLoader: true
    }
    return this._http.post(endpointUri, body).pipe(
      map((resp) => {
        return resp;
      })
    );
  }
  getRFPActivityData(customerId: string, httpMethod: string = 'GET'): Observable<any> {
    const fieldArr = queries.allfields;
    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    const body = {
      HttpMethod: httpMethod,
      JsonBody: "",
      SubUri: "/services/data/v51.0/query/?q=SELECT+CreatedDate,LastModifiedDate,Group_UW_Pend_Date__c,RFP_Submitted_Date__c,RFP_Status__c,Proposal_Declined_Date__c,Proposal_Approved_Date__c+from+Request_for_Proposal__c+where+id='" + customerId + "'",
      enableLoader: true
      // SubUri: "/services/data/v51.0/query/?q=SELECT+FIELDS(ALL)+from+Request_for_Proposal__c+where+id='" + customerId + "'"
      // SubUri: "/services/data/v51.0/query/?q=SELECT+CreatedDate,LastModifiedDate,Approval_Request_Submitted_del__c,Pended_by_Group_Underwriting_Picklist__c,Group_UW_Pend_Date__c,Pended_Comments__c,Status_Last_Changed__c,RFP_Status__c+from+Request_for_Proposal__c+where+id='" + customerId + "'"
    }
    return this._http.post(endpointUri, body).pipe(
      map((resp) => {
        return resp;
      })
    );
  }
  getSICCodeData(): Observable<any> {
    // End point url
    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    // For Active Cases count
    const sicCodeSubUri = "/services/data/v51.0/query/?q=" + queries.sicCodeQuery;
    const jsonBodyCountActiveCases = {
      JsonBody: '',
      SubUri: sicCodeSubUri,
      HttpMethod: 'GET',
      enableLoader: true
    };

    let sicCodes = this._http.post(endpointUri, jsonBodyCountActiveCases)

    return forkJoin([sicCodes]).pipe(
      map((resp) => {
        return resp;
      })
    );
  }

  ccaWorkflowTrigger(agentData: any): Observable<any> {
    const endpointUri = environment.endPoints.appFormsEndpoints.CCAProcessAPI;
    return this._http.post(endpointUri, agentData);
    // return this._http.post(endpointUri, agentData).pipe(
    //   map((response) => {
    //     return response;
    //   })
    // );
  }

  getSubAgentsForAdmin(formData: any): Observable<any> {
    // const mockURL = environment.adminSubAgentMock;
    const agentId = formData;
    const endpointUri = environment.endPoints.appFormsManagerEndpoints.endpointUri + agentId;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(endpointUri, { headers: headers }).pipe(
      map((resp) => {
        this.subRespList = resp;
        const dataFromResp: any = resp;
        this.subAgentsResp = trimEnd(reduce(dataFromResp.SubEmailList, (lst, em) => {
          if (em.Boss.Email.lastIndexOf('@') <= 35) {
            lst += !includes(lst, "'" + em.Boss.Email + "'") ? "'" + em.Boss.Email + "'," : '';
          }
          forEach(em.Subs, s => {
            if (s.Email.lastIndexOf('@') <= 35) {
              lst += !includes(lst, "'" + s.Email + "'") ? "'" + s.Email + "'," : '';
            }
          });
          return lst;
        }, ''), ',') || ("'" + agentId + "'");        

        return resp;
      })
    )
  }


  getSubAgents(formData: any): Observable<any> {
    const agentId = formData;
    const endpointUri = environment.endPoints.appFormsManagerEndpoints.endpointUri + agentId;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(endpointUri, { headers: headers }).pipe(
      map((resp) => {
        this.subRespList = resp;
        const dataFromResp: any = resp;
        this.subAgentsResp = trimEnd(reduce(dataFromResp.SubEmailList, (lst, em) => {
          if (em.Boss.Email.lastIndexOf('@') <= 35) {
            lst += !includes(lst, "'" + em.Boss.Email + "'") ? "'" + em.Boss.Email + "'," : '';
          }
          forEach(em.Subs, s => {
            if (s.Email.lastIndexOf('@') <= 35) {
              lst += !includes(lst, "'" + s.Email + "'") ? "'" + s.Email + "'," : '';
            }
          });
          return lst;
        }, ''), ',') || ("'" + agentId + "'");

        return resp;
      })
    )
  }

  getAllAccountsData(): Observable<any> {
    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    const body = {
      "JsonBody": "",
      "SubUri": "/services/data/v51.0/query/?q=SELECT Id,Name FROM Account WHERE GCAP_Owner_Email__c IN (" + this.subAgentsResp + ")",
      "HttpMethod": "GET",
      enableLoader: true
    }
    return this._http.post(endpointUri, body).pipe(
      map((resp) => {
        return resp;
      })
    );
  }
  getDashboardData(formData: any, isManager: boolean, isAdmin: boolean, subAgentVisited, nextRecordUrl?: string, rfpnextRecordUrl?: string): Observable<any>{
    let date = moment(new Date(
      moment().tz('America/New_York').format('YYYY-MM-DD')
    )).subtract(5, 'year').format('YYYY-MM-DD');
    date = date + "T00:00:00Z";
    if(!moment(date).isValid()) {
      date = "LAST_N_YEARS:5";
    }
    let url;
    let rfpUrl;
    if(nextRecordUrl) {
      url = nextRecordUrl;
    } else {
      url = "/services/data/v51.0/query";
    }
    if(rfpnextRecordUrl) {
      rfpUrl = rfpnextRecordUrl;
    } else {
      rfpUrl = "/services/data/v51.0/query";
    }
    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    let body;
    if (isManager === false && isAdmin === false) {
      let jsonBody;
      if((url && url !== "false") && (rfpUrl && rfpUrl !== "false")) {
        jsonBody = getJsonBody(getFetchRecordQueryForAgent(formData, url, date), rfpgetFetchRecordQueryForAgent(formData, rfpUrl, date));
      } else if ((url && url !== "false") && (rfpUrl && rfpUrl === "false")) {
        jsonBody = getJsonBody(getFetchRecordQueryForAgent(formData, url, date), "false");
      } else if ((url && url === "false") && (rfpUrl && rfpUrl !== "false")) {
        jsonBody = getJsonBody("false", rfpgetFetchRecordQueryForAgent(formData, url, date));
      }
      
      body =
      {
        Headers: null,
        HttpMethod: "POST",
        JsonBody: jsonBody,
        SubUri: "/services/data/v51.0/composite",
        enableLoader: true,
        allowInTraining: true
      }
    } else if (isManager === true && isAdmin === false) {
      let jsonBody;
      if((url && url !== "false") && (rfpUrl && rfpUrl !== "false")) {
        jsonBody = getJsonBody(getFetchRecordQueryForManager(this.subAgentsResp, url, date), rfpgetFetchRecordQueryForManager(this.subAgentsResp, rfpUrl, date));
      } else if ((url && url !== "false") && (rfpUrl && rfpUrl === "false")) {
        jsonBody = getJsonBody(getFetchRecordQueryForManager(this.subAgentsResp, url, date), "false");
      } else if ((url && url === "false") && (rfpUrl && rfpUrl !== "false")) {
        jsonBody = getJsonBody("false", rfpgetFetchRecordQueryForManager(this.subAgentsResp, rfpUrl, date));
      }
      body =
      {
        Headers: null,
        HttpMethod: "POST",
        JsonBody: jsonBody,
        SubUri: "/services/data/v51.0/composite",
        enableLoader: true,
        allowInTraining: true
      }
    } else if (isManager === false && isAdmin === true){
      let jsonBody;
      if((url && url !== "false") && (rfpUrl && rfpUrl !== "false")) {
        jsonBody = getJsonBody(getFetchRecordQueryForAdmin(url, date), rfpgetFetchRecordQueryForAdmin(rfpUrl, date));
      } else if ((url && url !== "false") && (rfpUrl && rfpUrl === "false")) {
        jsonBody = getJsonBody(getFetchRecordQueryForAdmin(url, date), "false");
      } else if ((url && url === "false") && (rfpUrl && rfpUrl !== "false")) {
        jsonBody = getJsonBody("false", rfpgetFetchRecordQueryForAdmin(rfpUrl, date));
      }
      body =
      {
        Headers: null,
        HttpMethod: "POST",
        JsonBody: jsonBody,
        SubUri: "/services/data/v51.0/composite",
        enableLoader: true,
        allowInTraining: true
      }
    }
    return this._http.post(endpointUri, body).pipe(map(resp => {
      return resp;
    }));
  }
  updateOwnerEmail(account: any, newOwnerEmail: string): Observable<any> {
    let formData = {};
    formData['GCAP_Owner_Email__c'] = newOwnerEmail;
    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    const jsonBody = JSON.stringify(formData);
    let accountId;
    if (account.Name.includes('RFP')) {
      accountId = account.RFP_Account__c;
    } else {
      accountId = account.Master_App_Account__c;
    }
    const body = {
      HttpMethod: "PATCH",
      JsonBody: jsonBody,
      SubUri: environment.endPoints.appFormsEndpoints.accountSubUri + accountId,
      enableLoader: true
    };
    return this._http.post(endpointUri, body).pipe(
      switchMap((data) => {
        let formDataVal = {};
        let body;
        if (account.Name.includes('RFP')) {
          formDataVal['RFP_Account__c'] = account.RFP_Account__c;
          body = {
            JsonBody: JSON.stringify(formDataVal),
            SubUri: environment.endPoints.appFormsEndpoints.rfpSubUri + account.Id,
            HttpMethod: "PATCH",
            enableLoader: true
          };
        } else {
          formDataVal['Master_App_Account__c'] = account.Master_App_Account__c;
          body = {
            JsonBody: JSON.stringify(formDataVal),
            SubUri: environment.endPoints.appFormsEndpoints.masterApplicationSubUri + account.Id,
            HttpMethod: "PATCH",
            enableLoader: true
          };
        }
        return this._http.post(endpointUri, body);
      }))
  }

  getContactTableInfo(accountId?: string, httpMethod: string = 'GET'): Observable<any> {
    // 
    accountId = accountId ? accountId : this.accountId;
    if (accountId) {
      const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
      const body = {
        "JsonBody": "",
        "SubUri": "/services/data/v51.0/query/?q=SELECT+Id,FirstName,LastName,Payroll__c,Email,Phone,MailingCity,MailingState,MailingStreet,MailingPostalCode+FROM+Contact+WHERE+AccountId+IN+('" + accountId + "')",
        "HttpMethod": httpMethod,
        enableLoader: true
      }
      return this._http.post(endpointUri, body).pipe(
        map((resp) => {
          // 
          return resp;
        })
      );
    }
  }
  emptyMetaData(MaId?: string, reqdata?: any, httpMethod: string = 'PATCH',laderNotRequired?: any): Observable<any> {
    if (MaId && reqdata) {
      const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
      // const reqdata = {};
      // const newMetadataValue = JSON.parse(metaData);
      // newMetadataValue.metaDataValue.stepData = null;
      // reqdata['GCAP_app_metadata__c'] = JSON.stringify(newMetadataValue);
      let body;
      if(laderNotRequired){
        body = {
          "JsonBody": JSON.stringify(reqdata),
          "SubUri": environment.endPoints.appFormsEndpoints.masterApplicationSubUri + MaId,
          "HttpMethod": httpMethod
        }
      }else{
        body = {
          "JsonBody": JSON.stringify(reqdata),
          "SubUri": environment.endPoints.appFormsEndpoints.masterApplicationSubUri + MaId,
          "HttpMethod": httpMethod,
          enableLoader: true
        }
      } 
      return this._http.post(endpointUri, body);
    }
  }
  fetchCommissionsObjectIds(MADetails: any, httpMethod: string = 'GET'): Observable<any> {
      const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
      let body = {
        "JsonBody": "",
        "HttpMethod": "GET",
        "SubUri": "/services/data/v51.0/query/?q=SELECT+Id,Percentage__c,Name+from+Commission__c+where+Master_Application__c='" + MADetails.id + "'+and+(Name='" + MADetails.name + "-1'+or+Name='"+ MADetails.name +"-2')",
      }
      return this._http.post(endpointUri, body);
  }

  fetchAgentsObjectIds(MADetails: any, httpMethod: string = 'GET'): Observable<any> {
    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    let body = {
      "JsonBody": "",
      "HttpMethod": "GET",
      "SubUri": "/services/data/v51.0/query/?q=SELECT+Id,Type__c,Name__c,Code__c+from+ACE_Appointment__c+where+Master_Application__c='" + MADetails.id + "'",
    }
    return this._http.post(endpointUri, body);
}

  fetchSeparateBilling(MADetails: any, httpMethod: string = 'GET'): Observable<any> {
    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    let body = {
      "JsonBody": "",
      "HttpMethod": "GET",
      "SubUri": "/services/data/v51.0/query/?q=SELECT+Id,Separate_Billing_Required__c+from+Master_Application_Extension__c+where+Master_Application__c='" + MADetails.id + "'",
    }
    return this._http.post(endpointUri, body);
  }

  updateAgentSplitInCommissionObj(reqdata: any,CommObjId): Observable<any> {
    //if (reqdata) {
      const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
      let body = {
        "JsonBody": JSON.stringify(reqdata),
        "HttpMethod": "PATCH",
        "SubUri": "/services/data/v51.0/sobjects/Commission__c/" + CommObjId
      }
        return this._http.post(endpointUri, body);
    //}
  }

  getUnderwritingOfferTypeExt(MADetails: any, httpMethod: string = 'GET'): Observable<any> {
    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    let body = {
      "JsonBody": "",
      "HttpMethod": "GET",
      "SubUri": "/services/data/v51.0/query/?q=SELECT+Id,GCAP_Owner_Selected_Underwriting_Type__c,Underwriting_Offer_Sold__c+from+Master_Application_Extension__c+where+Master_Application__c='" + MADetails.id + "'",
      enableLoader: true
    }
    return this._http.post(endpointUri, body);
  }

  getDurationElimination(MADetails: any, httpMethod: string = 'GET'): Observable<any> {
    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    let body = {
      "JsonBody": "",
      "HttpMethod": "GET",
      "SubUri": "/services/data/v51.0/query/?q=SELECT+Id,Benefit_Duration_and_Elimination_Period__c+from+Master_Application_Extension__c+where+Master_Application__c='" + MADetails.id + "'",
    }
    return this._http.post(endpointUri, body);
  }

  updateCaseIdOnMAExtensionObject(reqdata: any,extensionId, httpMethod: string = 'PATCH'): Observable<any> {
    if (reqdata) {
      const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
      let body = {
          "JsonBody": JSON.stringify(reqdata),
          "SubUri": "/services/data/v51.0/sobjects/Master_Application_Extension__c/" + extensionId,
          "HttpMethod": httpMethod,
          enableLoader: true
        }
      return this._http.post(endpointUri, body);
    }
  }
  // updateFileTransmissionDate(reqdata: any, httpMethod: string = 'POST'): Observable<any> {
  //   if (reqdata) {
  //     const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
  //     let body = {
  //         "JsonBody": JSON.stringify(reqdata),
  //         "SubUri": "/services/apexrest/selerixapi/filetransmission",
  //         "HttpMethod": 'POST',
  //         enableLoader: true
  //       }
  //     return this._http.post(endpointUri, body);
  //   }
  // }
  emptyRFPMetaData(RFPId?: string, reqdata?: any, httpMethod: string = 'PATCH'): Observable<any> {
    if (RFPId && reqdata) {
      const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
      const body = {
        "JsonBody": JSON.stringify(reqdata),
        "SubUri": environment.endPoints.appFormsEndpoints.rfpSubUri + RFPId,
        "HttpMethod": httpMethod,
        enableLoader: true
      }
      return this._http.post(endpointUri, body);
    }
  }

  getMACloneId(MaId?: string, offerType?:string, httpMethod: string = 'POST'): Observable<any> {
    if (MaId) {
      const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
      let formData = {};
      formData['Id'] = MaId;
      if(offerType !== ''){
        formData['offerType'] = offerType;
      }
      
      const body = {
        "JsonBody": JSON.stringify(formData),
        "SubUri": '/services/apexrest/GCAPFunctions/MAClone',
        "HttpMethod": httpMethod,
        enableLoader: true
      }
      return this._http.post(endpointUri, body).pipe(
        map((resp) => {
          return resp;
        })
      );
    }
  }
  getRFPCloneId(rfpId?: string, httpMethod: string = 'POST'): Observable<any> {
    if (rfpId) {
      const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
      let formData = {};
      formData['Id'] = rfpId;
      const body = {
        "JsonBody": JSON.stringify(formData),
        "SubUri": '/services/apexrest/GCAPFunctions/RFPClone',
        "HttpMethod": httpMethod,
        enableLoader: true
      }
      return this._http.post(endpointUri, body).pipe(
        map((resp) => {
          return resp;
        })
      );
    }
  }
  getContactandHRData(accountId?: string, httpMethod: string = 'GET'): Observable<any> {
    // 
    accountId = accountId ? accountId : this.accountId;
    if (accountId) {
      const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
      const body = {
        "JsonBody": "",
        "SubUri": "/services/data/v51.0/query/?q=SELECT+Id,FirstName,LastName,Payroll__c,Email,Phone,MailingCity,MailingState,MailingStreet,MailingPostalCode+FROM+Contact+WHERE+AccountId+IN+('" + accountId + "')",
        //"SubUri": "/services/data/v51.0/query/?q=SELECT+Id,FirstName,LastName,Payroll__c,Email,Phone,MailingCity,MailingState,MailingStreet,MailingPostalCode+FROM+Contact+WHERE+AccountId+IN+('" + accountId + "') and Payroll__c = false",
        // SELECT+Name,Type,BillingCity,BillingStreet,BillingState,BillingPostalCode+from+Account+where+Id='" + accountId + "'",
        "HttpMethod": httpMethod,
        enableLoader: true
      }
      return this._http.post(endpointUri, body).pipe(
        map((resp) => {
          // 
          return resp;
        })
      );
    }
  }

  getOfferTypeData(accountId?: string, httpMethod: string = 'GET'): Observable<any> {
    // 
    accountId = accountId ? accountId : this.accountId;
    if (accountId) {
      const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
      const body = {
        "JsonBody": "",
        "SubUri": "/services/apexrest/masterapplication?query=SELECT+Id,GCAP_Owner_Selected_Underwriting_Type__c,Underwriting_Offer_Sold__c+from+Master_Application__c+WHERE+Master_App_Account__c='" + accountId + "'" ,
        "HttpMethod": httpMethod,
        enableLoader: true
      }
      return this._http.post(endpointUri, body).pipe(
        map((resp) => {
          // 
          return resp;
        })
      );
    }
  }

  getUnderWritingOfferType(MADetails: any, httpMethod: string = 'GET'): Observable<any> {
    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    let body = {
      "JsonBody": "",
      "HttpMethod": "GET",
      "SubUri": "/services/data/v51.0/query/?q=SELECT+Id,GCAP_Owner_Selected_Underwriting_Type__c+from+Master_Application_Extension__c+where+Master_Application__c='" + MADetails.id + "'",
    }
    return this._http.post(endpointUri, body);
  }

  getLoggedInUserAccountData(loginEmail?: string, httpMethod: string = 'GET'): Observable<any> {
    // 
    if (loginEmail) {
      const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
      const body = {
        "JsonBody": "",
        "SubUri": "/services/data/v51.0/query/?q=SELECT+Id,Name,Type,BillingCity,BillingStreet,BillingState,BillingPostalCode+FROM+Account+WHERE+GCAP_Owner_Email__c='" + loginEmail + "' +and+GCAP_Owner_Email__c IN (" + this.subAgentsResp + ")",
        "HttpMethod": httpMethod,
        enableLoader: true
      }
      return this._http.post(endpointUri, body).pipe(
        map((resp) => {
          // 
          return resp;
        })
      );
    }
  }
  getRateClass(url, httpMethod: string = 'GET'): Observable<any> {
    // 
    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    const body = {
      "JsonBody": "",
      "SubUri": url,
      "HttpMethod": httpMethod,
      enableLoader: true
    }
    return this._http.post(endpointUri, body).pipe(
      map((resp) => {
        // 
        return resp;
      })
    );
  }
  //Commented as the same functionality can be achieved with getCustomerInfo

  // getDefaultFieldValues(customerId: string, httpMethod: string = 'GET'): Observable<any> {
  //   
  //   const fieldArr = queries.defaultValuesForFields;
  //   const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
  //   const body = {
  //     HttpMethod: httpMethod,
  //     JsonBody: "",
  //     SubUri: "/services/data/v51.0/sobjects/Master_Application__c/" + customerId + "?fields=" + fieldArr.toString()
  //   }
  //   return this._http.post(endpointUri, body).pipe(
  //     map((resp) => {
  //       
  //       return resp;
  //     })
  //   );
  // }

  fetchDocument(url: string, httpMethod: string = 'GET') {
    const endpointUri = `${environment.endPoints.appFormsEndpoints.endpointUri}`;
    const body = {
      HttpMethod: httpMethod,
      JsonBody: '',
      SubUri: `${url}`,
      enableLoader: true
    };
    return this._http.post(endpointUri, body, {
      responseType: 'blob'
    });
  }

  saveRfpformData(formData: any, httpMethod: string = 'POST', accountReq: any = {}): Observable<any> {
    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    const jsonBody = {
      JsonBody: JSON.stringify(accountReq),
      SubUri: environment.endPoints.appFormsEndpoints.accountSubUri,
      HttpMethod: 'POST',
      enableLoader: true
    };
    return this._http.post(endpointUri, jsonBody).pipe(
      switchMap((data) => {
        this.accountId = data['id'];
        const accountId = this.accountId;
        formData.RFP_Account__c = this.accountId;
        formData.GCAP_app_metadata__c = undefined;
        const body = {
          HttpMethod: httpMethod,
          JsonBody: JSON.stringify(formData),
          SubUri: environment.endPoints.appFormsEndpoints.rfpSubUri,
          enableLoader: true
        };
        return this._http.post(endpointUri, body).pipe(
          map((resp) => {
            const response: any = resp;
            const data = {
              errors: [],
              id: response.id,
              success: true,
              accountId: accountId
            }
            this._store.dispatch(saveAccountIdDetails({ payload: data }));
            return resp;
          })
        );
      })
    );
  }

  updateRfpformData(accountId: any, formData: any, recordId: string, httpMethod: string = 'PATCH'): Observable<any> {
    formData.RFP_Account__c = formData.RFP_Account__c ? formData.RFP_Account__c : accountId;
    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    const jsonBody = JSON.stringify(formData);
    const body = {
      HttpMethod: httpMethod,
      JsonBody: jsonBody,
      SubUri: environment.endPoints.appFormsEndpoints.rfpSubUri + recordId,
      enableLoader: true
    };
    return this._http.post(endpointUri, body);
  }

  submitRfpformData(accountId: any, formData: any, recordId: string, httpMethod: string = 'PATCH'): Observable<any> {
    formData.RFP_Account__c = formData.RFP_Account__c ? formData.RFP_Account__c : accountId;
    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    const finalBody = {
      Id: recordId
    }
    const jsonBody = JSON.stringify(finalBody);
    const body = {
      HttpMethod: httpMethod,
      JsonBody: jsonBody,
      SubUri: '/services/apexrest/GCAPFunctions/RFPSubmit',
      enableLoader: true
    };
    return this._http.post(endpointUri, body);
  }

  getMessageHistory(id: string, isMasterApp = true): Observable<any> {

    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    const qry = isMasterApp ? queries.fetchMessageListMA + id : queries.fetchMessageListRFP + id
    const body = {
      HttpMethod: 'GET',
      JsonBody: '',
      SubUri: '/services/data/v51.0/query/?q=' + qry + "'+LIMIT+200",
      enableLoader: true
    };

    return this._http.post(endpointUri, body).pipe(
      map((resp) => {

        return resp;
      })
    );
  }

  getVersionHistory(id: string, httpMethod: string = 'GET'): Observable<any> {
    // 
    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    const body = {
      HttpMethod: httpMethod,
      JsonBody: '',
      SubUri: "/services/data/v51.0/query/?q=SELECT+Id,Name,Request_for_Proposal__c,Selected__c,Status__c,Version_Notes__c+from+RFP_Version__c+where+Request_for_Proposal__c='" + id + "'",
      enableLoader: true
    };
    // SubUri: '/services/data/v51.0/query/?q=' + queries.fetchRfpVersionList + id
    // SubUri: "/services/data/v51.0/query/?q=SELECT+Id,Name,Request_for_Proposal__c,Selected__c,Status__c,Version_Notes__c+from+RFP_Version__c+where+Request_for_Proposal__c='a072F000005dUukQAE'"

    return this._http.post(endpointUri, body).pipe(
      map((resp) => {
        // 
        return resp;
      })
    );
  }

  updateSeclected(versionData) {
    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    // const data = cloneDeep(versionData);
    const data = {};
    data["Selected__c"] = versionData.Selected__c;
    const body = {
      HttpMethod: 'PATCH',
      JsonBody: JSON.stringify(data),
      SubUri: environment.endPoints.appFormsEndpoints.rfpVersionUri + versionData.Id,
      enableLoader: true
    };

    return this._http.post(endpointUri, body).pipe(
      map((resp) => {
        // 
        return resp;
      })
    );
  }

  updateStatus(versionData) {
    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    // const data = cloneDeep(versionData);
    const data = {};
    data["Status__c"] = versionData.Status__c;
    const body = {
      HttpMethod: 'PATCH',
      JsonBody: JSON.stringify(data),
      SubUri: environment.endPoints.appFormsEndpoints.rfpVersionUri + versionData.Id,
      enableLoader: true
    };

    return this._http.post(endpointUri, body).pipe(
      map((resp) => {
        // 
        return resp;
      })
    );
  }

  sendMessage(formData: any, httpMethod: string = 'POST'): Observable<any> {

    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    const jsonBody = {
      JsonBody: JSON.stringify(formData),
      SubUri: environment.endPoints.appFormsEndpoints.messagingSubUri,
      HttpMethod: httpMethod,
      enableLoader: true
    };

    return this._http.post(endpointUri, jsonBody).pipe(
      map(resp => resp));

  }

  saveIMOcode(body: any, httpMethod: string = 'POST'): Observable<any> {

    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    const jsonBody = {
      JsonBody: JSON.stringify(body),
      SubUri: '/services/data/v58.0/composite/sobjects',
      HttpMethod: httpMethod,
      enableLoader: true
    };

    return this._http.post(endpointUri, jsonBody).pipe(
      map(resp => resp));

  }

  deleteAppointmentData(id: any, body: any, httpMethod: string = 'DELETE'): Observable<any> {
    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    const jsonBody = {
      JsonBody: "",
      SubUri: "/services/data/v58.0/composite/sobjects?ids=" + id,
      HttpMethod: httpMethod,
      enableLoader: true
    };
    return this._http.post(endpointUri, jsonBody).pipe(
      map(resp => resp));
  }


  readMessages(body: any, httpMethod: string = 'PATCH'): Observable<any> {

    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    const jsonBody = {
      JsonBody: JSON.stringify(body),
      SubUri: '/services/data/v51.0/composite/sobjects',
      HttpMethod: httpMethod,
      enableLoader: true
    };

    return this._http.post(endpointUri, jsonBody).pipe(
      map(resp => resp));

  }

  getUnreadCount(email,isAdmin, forRFP = false) {

    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    // old query
    // let SubUri = `/services/data/v51.0/query/?q=SELECT+COUNT(Id)+NrOfMessages,MAX(Email_Sent_Date__c)+latest_timestamp_msg,Master_Application__c+FROM+Message__c+WHERE+Master_Application__r.Master_App_Account__r.GCAP_Owner_Email__c+IN+(` + this.subAgentsResp + `)+AND+Read_Status__c='New'+AND+GCAP_Email__c!='${email}'+GROUP+BY+Master_Application__c`;
    // let SubUri = `/services/data/v51.0/query/?q=SELECT+COUNT(Id)+NrOfMessages,MAX(Email_Sent_Date__c)+latest_timestamp_msg,Master_Application__c+FROM+Message__c+WHERE+Master_Application__r.Master_App_Account__r.GCAP_Owner_Email__c='${email}'+AND+Read_Status__c='New'+AND+GCAP_Email__c!='${email}'+GROUP+BY+Master_Application__c`;
    // new query to retrive Read_Status__c field
    // let SubUri = `/services/data/v51.0/query/?q=SELECT+COUNT(Id)+NrOfMessages,MAX(Email_Sent_Date__c)+latest_timestamp_msg,Master_Application__c,Read_Status__c+FROM+Message__c+WHERE+Master_Application__r.Master_App_Account__r.GCAP_Owner_Email__c+IN+(` + this.subAgentsResp + `)+AND+GCAP_Email__c!='${email}'+GROUP+BY+Master_Application__c,Read_Status__c`;
    // if(isAdmin && isAdmin == true){
    //   SubUri = `/services/data/v51.0/query/?q=SELECT+COUNT(Id)+NrOfMessages,MAX(Email_Sent_Date__c)+latest_timestamp_msg,Master_Application__c,Read_Status__c+FROM+Message__c+WHERE+GCAP_Email__c!='${email}'+GROUP+BY+Master_Application__c,Read_Status__c`;
    // }
    // if (forRFP) {
      // old query
      // SubUri = `/services/data/v51.0/query/?q=SELECT+COUNT(Id)+NrOfMessages,MAX(Email_Sent_Date__c)+latest_timestamp_msg,Request_for_Proposal__c+FROM+Message__c+WHERE+Request_for_Proposal__r.RFP_Account__r.GCAP_Owner_Email__c+IN+(` + this.subAgentsResp + `)+AND+Read_Status__c='New'+AND+GCAP_Email__c!='${email}'+GROUP+BY+Request_for_Proposal__c`;
      // SubUri = `/services/data/v51.0/query/?q=SELECT+COUNT(Id)+NrOfMessages,MAX(Email_Sent_Date__c)+latest_timestamp_msg,Request_for_Proposal__c+FROM+Message__c+WHERE+Request_for_Proposal__r.RFP_Account__r.GCAP_Owner_Email__c='${email}'+AND+Read_Status__c='New'+AND+GCAP_Email__c!='${email}'+GROUP+BY+Request_for_Proposal__c`;
      // new query retrive Read_Status__c field
    //   SubUri = `/services/data/v51.0/query/?q=SELECT+COUNT(Id)+NrOfMessages,MAX(Email_Sent_Date__c)+latest_timestamp_msg,Request_for_Proposal__c,Read_Status__c+FROM+Message__c+WHERE+Request_for_Proposal__r.RFP_Account__r.GCAP_Owner_Email__c+IN+(` + this.subAgentsResp + `)+AND+GCAP_Email__c!='${email}'+GROUP+BY+Request_for_Proposal__c,Read_Status__c`;
    // }
    // if(forRFP && isAdmin && isAdmin == true){
    //   SubUri = `/services/data/v51.0/query/?q=SELECT+COUNT(Id)+NrOfMessages,MAX(Email_Sent_Date__c)+latest_timestamp_msg,Request_for_Proposal__c,Read_Status__c+FROM+Message__c+WHERE+GCAP_Email__c!='${email}'+GROUP+BY+Request_for_Proposal__c,Read_Status__c`;
    // }
    let SubUri = `/services/data/v51.0/query/?q=SELECT+COUNT(Id)+NrOfMessages,MAX(Email_Sent_Date__c)+latest_timestamp_msg,Master_Application__c,Read_Status__c+FROM+Message__c+WHERE+Master_Application__r.Master_App_Account__r.GCAP_Owner_Email__c+IN+(` + this.subAgentsResp + `)+GROUP+BY+Master_Application__c,Read_Status__c`;
    if(isAdmin && isAdmin == true){
      SubUri = `/services/data/v51.0/query/?q=SELECT+COUNT(Id)+NrOfMessages,MAX(Email_Sent_Date__c)+latest_timestamp_msg,Master_Application__c,Read_Status__c+FROM+Message__c+GROUP+BY+Master_Application__c,Read_Status__c`;
    }
    if (forRFP) {
      SubUri = `/services/data/v51.0/query/?q=SELECT+COUNT(Id)+NrOfMessages,MAX(Email_Sent_Date__c)+latest_timestamp_msg,Request_for_Proposal__c,Read_Status__c+FROM+Message__c+WHERE+Request_for_Proposal__r.RFP_Account__r.GCAP_Owner_Email__c+IN+(` + this.subAgentsResp + `)+GROUP+BY+Request_for_Proposal__c,Read_Status__c`;
    }
    if(forRFP && isAdmin && isAdmin == true){
      SubUri = `/services/data/v51.0/query/?q=SELECT+COUNT(Id)+NrOfMessages,MAX(Email_Sent_Date__c)+latest_timestamp_msg,Request_for_Proposal__c,Read_Status__c+FROM+Message__c+GROUP+BY+Request_for_Proposal__c,Read_Status__c`;
    }
    const body = {
      HttpMethod: "GET",
      JsonBody: "",
      SubUri
    }

    return this._http.post(endpointUri, body).pipe(
      map((resp) => {
        return resp;
      })
    );
  }

  getUnreadCountVersion(email, isAdmin) {

    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    let SubUri;
    if (!isAdmin) {
      SubUri = `/services/data/v51.0/query/?q=SELECT+COUNT(Id)+NrOfMessages,Request_for_Proposal__c+FROM+RFP_Version__c+WHERE+Request_for_Proposal__r.RFP_Account__r.GCAP_Owner_Email__c+IN+(` + this.subAgentsResp + `)+AND+Status__c='New'+GROUP+BY+Request_for_Proposal__c`;
    } else {
      SubUri = `/services/data/v51.0/query/?q=SELECT+COUNT(Id)+NrOfMessages,Request_for_Proposal__c+FROM+RFP_Version__c+WHERE+Status__c='New'+GROUP+BY+Request_for_Proposal__c`;
    }
    const body = {
      HttpMethod: "GET",
      JsonBody: "",
      SubUri
    }

    return this._http.post(endpointUri, body).pipe(
      map((resp) => {
        return resp;
      })
    );
  }

  convertRfpToMA(body) {
    const endpointUri = environment.endPoints.appFormsEndpoints.endpointUri;
    body.maOfferType = "Custom";
    const jsonBody = {
      JsonBody: JSON.stringify(body),
      SubUri: environment.endPoints.appFormsEndpoints.convertRfpToMa,
      HttpMethod: 'PUT',
      enableLoader: true
    };

    return this._http.post(endpointUri, jsonBody).pipe(
      map(resp => resp));
  }

  getAgentHirearchyDetails(userMailId) {
    const agentId = userMailId;
    const URL = environment.endPoints.agentDetails + agentId;
    const headers = new HttpHeaders().set('Content-Type', 'application/json').set('loader', 'notrequired');
    return this._http.get(URL, { headers: headers }).pipe(
      map((resp) => {
        return resp;
      })
    );
  }

}

