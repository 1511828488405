import { createReducer, on } from '@ngrx/store';
import { sicRateClassInitialState } from '../initial-state';
import { reset, saveSicRateClass } from '../actions';




const _sicRateClassReducer = createReducer(
  sicRateClassInitialState,
  on(saveSicRateClass, (state, { payload }) => ({ ...state, ...payload })),
  on(reset, (state) => ({}))
);

export function sicRateClassReducer(state = sicRateClassInitialState, action) {
  return _sicRateClassReducer(state, action);
}
