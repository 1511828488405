import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DownloadFileDirective } from './shared/download-file.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [DownloadFileDirective],
  exports: [DownloadFileDirective]
})
export class WidgetsModule { }
