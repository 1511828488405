import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppStepperService {
  private stepsActivity = new BehaviorSubject<any>(null);
  public stepsActivity$ = this.stepsActivity.asObservable();

  private stepsArr = [];
  skipStepId: any;
  stepsValidityStatus = {};
  dropdownData: any;
  dropdownExtData: any;
  payrollFrequencyData:any;

  constructor() {}
  /** Update Stepper information in normal scenario */
  updateStepActivity(stepActivity): void {
    this.stepsActivity.next(stepActivity);
  }

  /** Store the step history */
  updateStepId(id: number) {
    if (!this.stepsArr.includes(id)) {
      this.stepsArr.push(id);
    }
  }

  /** Get the max step id navigated*/
  getLatestStepId(): number {
    return Math.max(...this.stepsArr);
  }

  /** Update stepper config for Edit scenario */
  getLatestStepActivity(stepsActivity, id) {
    stepsActivity.forEach((step) => {
      if (step.id === id + 1 && step.id < this.getLatestStepId()) {
        stepsActivity[id].showEdit = false;
        stepsActivity[id].isActive = true;
        stepsActivity[id].percentage = 100;
        stepsActivity[id].showPercentage = false;
        stepsActivity[step.id - 1].isError = !this.stepsValidityStatus[step.id];
        stepsActivity[step.id - 1].showImg = true;
        stepsActivity[id].showMobileSectionLabel = true;
        stepsActivity[step.id - 1].currentStep = true;
      } else if (step.id < this.getLatestStepId()) {
        stepsActivity[step.id - 1].showEdit = true;
        stepsActivity[step.id - 1].isActive = true;
        stepsActivity[step.id - 1].isError = !this.stepsValidityStatus[step.id];
        stepsActivity[step.id - 1].showImg = true;
        stepsActivity[step.id - 1].percentage = 0;
        stepsActivity[step.id - 1].showPercentage = false;
        stepsActivity[step.id - 1].showMobileSectionLabel = true;
        stepsActivity[step.id - 1].currentStep = false;
      }
    });
    return stepsActivity;
  }

  hideAllEditButtons(stepsActivity) {
    stepsActivity.forEach((step) => {
      stepsActivity[step.id].showEdit = false;
    });
    return stepsActivity;
  }

  updateSkipStepId(id) {
    this.skipStepId = id;
  }

  getSkipStepId() {
    return this.skipStepId;
  }
  skipStepById(stepsActivity, id) {
    stepsActivity.forEach((step) => {
      if (id === step.id) {
        stepsActivity[id - 1].showEdit = false;
        stepsActivity[id - 1].isActive = false;
        stepsActivity[id - 1].showImg = true;
        stepsActivity[id - 1].percentage = 0;
        stepsActivity[id - 1].showPercentage = false;
        stepsActivity[id - 1].showMobileSectionLabel = false;
        stepsActivity[id - 1].currentStep = false;
      }
    });
    return stepsActivity;
  }

  getUpdatedPercentageActivity({
    stepsActivity,
    valueObj,
    totalFields,
  }): number {
    const filledFields = Object.values(valueObj).filter((value) => {
      if (value) {
        return value;
      }
    });
    const totalFilledFields = filledFields.length;
    const totalPercentage = (totalFilledFields / totalFields) * 100;
    stepsActivity = stepsActivity.map((step) => {
      if (step.currentStep) {
        step.percentage = Math.trunc(totalPercentage);
      }
      return step;
    });
    return stepsActivity;
  }

  updateStepValidityStatus(stepId, status) {
    this.stepsValidityStatus[stepId] = status;
  }

  validateStatusOfSteps(): boolean {
    var isValid = true;
    Object.keys(this.stepsValidityStatus).forEach((key) => {
      if (!this.stepsValidityStatus[key]) {
        isValid = false;
      }
    });
    return isValid;
  }

  validateStatusOfStepsEdit(statusData): boolean {
    var isValid = true;
    Object.keys(statusData).forEach((key) => {
      if (statusData[key].isError && key !== '7') {
        isValid = false;
      }
    });
    return isValid;
  }

  setDropdownData(data: any): void {
    this.dropdownData = data;
  }

  getDropdownData(): any {
    return this.dropdownData;
  }

  setExtDropdownData(data: any): void {
    this.dropdownExtData = data;
  }

  getExtDropdownData(): any {
    return this.dropdownExtData;
  }

  setPayrollFrequencyData(data: any): void {
    this.payrollFrequencyData = data;
  }

  getPayrollFrequencyData(): any {
    return this.payrollFrequencyData;
  }

  resetStepper(): void {
    this.skipStepId = undefined;
    this.stepsArr = [];
    this.stepsValidityStatus = {};
  }

  isPopupRequired = new BehaviorSubject<any>(null);
  getPopup(data: any){
    this.isPopupRequired.next(data);
  }

  getIndex = new BehaviorSubject<any>(null);
  sendIndex(data: any){
    this.getIndex.next(data);
  }
}
