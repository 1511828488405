import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from 'src/app/globals';
import { environment } from 'src/environments/environment';


@Component({
    selector: "app-login-unqork",
    templateUrl: "./login-unqork.template.html"
})
export class LoginUnqorkComponent implements OnInit {


    constructor(
        private _router: Router,
        private route: ActivatedRoute,
        private globals: Globals
    ) {
    }

    ngOnInit() {
        
           
        
         
        if(localStorage.getItem('userInfo')) {
            this._router.navigate(["dashboard"]);
        }
          
    }

}

// localhost:3000/login/unqork?eyAgICAgImlzQWdlbnQiOiB0cnVlLCAgICAgImVtYWlsIjogImFnZW5jeXBvcnRhbC5zaXQxMUBteWNvbWJpbmVkLm5ldCIsICAgICAiUG9zaXRpb25Db2RlIjogIjE4IiwgICAgICJGaXJzdE5hbWUiOiAiTEtBQUFLTCIsICAgICAiTGFzdE5hbWUiOiAiQSdBTEpFS0EgTEwiIH0=

// http://localhost:3000/login/unquork?eyAgICAgImlzQWdlbnQiOiB0cnVlLCAgICAgImVtYWlsIjogImFnZW5jeXBvcnRhbC5zaXQxMUBteWNvbWJpbmVkLm5ldCIsICAgICAiUG9zaXRpb25Db2RlIjogIjE4IiwgICAgICJGaXJzdE5hbWUiOiAiTEtBQUFLTCIsICAgICAiTGFzdE5hbWUiOiAiQSdBTEpFS0EgTEwiIH0=

// http://localhost:3000/login/unqork?eyAgICAgImlzQWdlbnQiOiB0cnVlLCAgICAgImVtYWlsIjogInRlc3RpbmdhZ2VudC5zaXQyMEBteWNvbWJpbmVkLm5ldCIsICAgICAiUG9zaXRpb25Db2RlIjogIjE2IiwgICAgICJGaXJzdE5hbWUiOiAiSkFKS0xFQSIsICAgICAiTGFzdE5hbWUiOiAiTExMQSIgfQ==