import { createReducer, on } from '@ngrx/store';
import {
    saveHrContactDetails,
    reset
} from '../actions/hr-contact-details.action';
import { hrContactDetailsInitialState } from '../initial-state';


const _hrContactDetailsReducer = createReducer(
    hrContactDetailsInitialState,
    on(saveHrContactDetails, (state, { payload }) => ({ ...state, ...payload })),
    on(reset, (state) => ({}))
);

export function hrContactDetailsReducer(state = hrContactDetailsInitialState, action) {
    return _hrContactDetailsReducer(state, action);
}