import { Injectable } from '@angular/core';
import { CanActivate, Router, NavigationEnd, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { MsalService } from '@azure/msal-angular';

@Injectable()
export class MsalAuthGuard implements CanActivate {
    isUnqork;
    constructor(
        private _msalService: MsalService
    ) {

    }

    canActivate(next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): boolean {
        this.isUnqork = localStorage.getItem('isFromUnqork');
        if(this.isUnqork == '1') {
            return true;
        }
        else {
            if (this._msalService.getAccount()) {
                return true;
            }
             else {
                this._msalService.loginRedirect();
                return false;
            }
        }
    }
}