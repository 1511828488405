import { createReducer, on } from '@ngrx/store';
import { reset, saveSicCodeDetails } from '../actions';

import { sicCodeInitialState } from '../initial-state';

const _sicCodeReducer = createReducer(
  sicCodeInitialState,
  on(saveSicCodeDetails, (state, { payload }) => ({ ...state, ...payload })),
  on(reset, (state) => ({}))
);

export function sicCodeReducer(state = sicCodeInitialState, action) {
  return _sicCodeReducer(state, action);
}
