import { createReducer, on } from '@ngrx/store';
import { reset, saveFileIdDetails } from '../actions/file-id.action';

import { accountIdInitialState, fileIdInitialState } from '../initial-state';

const _fileIdReducer = createReducer(
  fileIdInitialState,
  on(saveFileIdDetails, (state, { payload }) => ({ ...state, ...payload })),
  on(reset, (state) => ({}))
);

export function fileIdReducer(state = fileIdInitialState, action) {
  return _fileIdReducer(state, action);
}
