import { createReducer, on } from '@ngrx/store';
import {
    saveBusinuessDetails,
    reset
} from '../actions/businuess-details.action';
import { businuessDetailsInitialState } from '../initial-state';


const _businuessDetailsReducer = createReducer(
    businuessDetailsInitialState,
    on(saveBusinuessDetails, (state, { payload }) => ({ ...state, ...payload })),
    on(reset, (state) => ({}))
);

export function businuessDetailsReducer(state = businuessDetailsInitialState, action) {
    return _businuessDetailsReducer(state, action);
}