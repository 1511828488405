import { Injectable, Injector } from "@angular/core";

// Util Imports
import _get from "lodash-es/get";
import _merge from "lodash-es/merge";
import _some from "lodash-es/some";
import _set from "lodash-es/set";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { environment } from "../environments/environment";
import { forkJoin, Observable, of, throwError } from "rxjs";
import { concatMap, mergeMap, tap } from "rxjs/operators";
import { filter, map, catchError } from "rxjs/operators";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd } from "@angular/router";
import { isEmpty } from "lodash-es";

@Injectable({
  providedIn: "root"
})
export class AppContextService {
  private configBlobUrl = environment.configBlobUrl || `/assets/config`;

  constructor(
    private injector: Injector,
    private _http: HttpClient,
    private _title: Title,
    private _router: Router,
    private _activatedRoute: ActivatedRoute
  ) {
  }

  private _appContext: any;

  get(context: string): any {
    return _get(this._appContext, context);
  }

  getSelfUrlsRecursively(value, key): Observable<any>[] {
    const studioConfig$ = [];

    if (value.hasOwnProperty("__$$selfUrl")) {
      studioConfig$.push(
        this._getSelfJson(this._appContext, value["__$$selfUrl"], key)
      );
    }

    if (_some(value, "__$$selfUrl")) {
      const childWithSelfUrl = this._childHasSelfUrl(value, key);

      for (const child of childWithSelfUrl) {
        const selfUrl = _get(this._appContext, `${child}.__$$selfUrl`);
        studioConfig$.push(this._getSelfJson(this._appContext, selfUrl, child));
      }
    }

    return studioConfig$;
  }

  load(configPath?: string): Observable<any> {
    return this._http
      .get(`${this.configBlobUrl}${configPath}/app-config.json`)
      .pipe(
        concatMap(res => {
          const studioConfig$: Observable<any>[] = [];
          this._appContext = this._merge(res, {});

          for (const [key, value] of Object.entries(res)) {
            const _studioConfig$ = this.getSelfUrlsRecursively(value, key);
            studioConfig$.push(..._studioConfig$);
          }

          return isEmpty(studioConfig$) ? of(true) : forkJoin(studioConfig$);
        }),
        map(() => {
          this._replaceEnvironmentVariables(this._appContext);
          return this._appContext;
        }),
        catchError((err: any) => {
          return throwError(err);
        })
      );
  }

  trackRouterEvents(): void {
    this._router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this._activatedRoute),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === "primary"),
        mergeMap(route => route.data)
      )
      .subscribe(event => this.setTitle(event["title"]));
  }

  setTitle(title: string): void {
    const appTitle = `${this.get(`common.documentTitle`) || `Chubb Studio`} ${
      title ? `- ${title}` : ""
    }`;
    this._title.setTitle(appTitle);
  }

  private _merge(jsonObject: any, jsObject: any): any {
    let mergedObj: any = {};
    if (jsObject && jsonObject) {
      mergedObj = _merge(jsonObject, jsObject);
    } else if (jsObject) {
      mergedObj = jsObject;
    } else if (jsonObject) {
      mergedObj = jsonObject;
    }

    return mergedObj;
  }

  private _childHasSelfUrl(obj, parentKey) {
    const selfUrlChildren = [];
    for (const [key, value] of Object.entries(obj)) {
      if (value.hasOwnProperty("__$$selfUrl")) {
        selfUrlChildren.push(`${parentKey}.${key}`);
      }
    }
    return selfUrlChildren;
  }

  private _getSelfJson(
    appContext: any,
    path: string,
    key: string
  ): Observable<any> {
    return this._http.get(path).pipe(
      map(partnerJson => {
        if (partnerJson.hasOwnProperty("__$$defaultUrl")) {
          this._getDefaultJson(partnerJson["__$$defaultUrl"]).then(
            defaultJson => {
              delete partnerJson["__$$defaultUrl"];
              const combinedSelfJson = Object.assign(defaultJson, partnerJson);
              _set(appContext, key, combinedSelfJson);
            }
          );
        } else {
          _set(appContext, key, partnerJson);
        }
      })
    );
  }

  private _getDefaultJson(path: string) {
    return this._http.get(path).toPromise();
  }

  private _replaceEnvironmentVariables(obj) {
    for (const k in obj) {
      if (obj.hasOwnProperty(k)) {
        if (typeof obj[k] === "object" && obj[k] !== null) {
          this._replaceEnvironmentVariables(obj[k]);
        } else if (
          typeof obj[k] === "string" &&
          obj[k].startsWith("__$$environment")
        ) {
          obj[k] = _get(environment, obj[k].replace("__$$environment.", ""));
        }
      }
    }
  }
}
