import { createReducer, on } from '@ngrx/store';
import { 
    reset, 
    saveProductsDetails 
} from '../actions/proposed-products.actions';

import { proposedProductsInitialState } from '../initial-state';


const _proposedProductsReducer = createReducer(
    proposedProductsInitialState,
    on(saveProductsDetails, (state, { payload }) => ({ ...state, ...payload })),
    on(reset, (state) => ({}))
);

export function proposedProductsReducer(state = proposedProductsInitialState, action) {
    return _proposedProductsReducer(state, action);
}