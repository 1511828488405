import { createReducer, on } from '@ngrx/store';
import {
    saveSelectedCustomerDetails,
    reset
} from '../actions/customer-details.action';
import { customerDetailsInitialState } from '../initial-state';


const _customerDetailsReducer = createReducer(
    customerDetailsInitialState,
    on(saveSelectedCustomerDetails, (state, { payload }) => ({ ...state, ...payload })),
    on(reset, (state) => ({}))
);

export function customerDetailsReducer(state = customerDetailsInitialState, action) {
    return _customerDetailsReducer(state, action);
}
