import { createReducer, on } from '@ngrx/store';
import { reset, saveAccountIdDetails } from '../actions/account-Id.action';

import { accountIdInitialState } from '../initial-state';

const _accountIdReducer = createReducer(
  accountIdInitialState,
  on(saveAccountIdDetails, (state, { payload }) => ({ ...state, ...payload })),
  on(reset, (state) => ({}))
);

export function accountIdReducer(state = accountIdInitialState, action) {
  return _accountIdReducer(state, action);
}
