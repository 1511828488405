import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppContextService } from '../../app.context.service';

@Component({
  selector: 'app-studio-public-layout',
  templateUrl: './public-layout.component.html',
  styleUrls: ['./public-layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PublicLayoutComponent implements OnInit {
  trainingMode = false;
  constructor(private _appContext: AppContextService,
    private _store: Store<any>,
    private _cd: ChangeDetectorRef) {}

  ngOnInit() {
    this._store.select('dashboardDetails').subscribe(state => {
      if(state && state.appMode && state.appMode === 'training') {
        this.trainingMode = true;
      } else {
        this.trainingMode = false;
      }
      this._cd.detectChanges();
    })
  }
}
