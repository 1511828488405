import { Injectable } from '@angular/core';
import * as studioDomains from '../../../config/domains-mapping.json';

@Injectable({
  providedIn: 'root',
})
export class DomainsMappingService {
  constructor() {}

  getMapping(): any {
    return studioDomains.default;
  }
}
