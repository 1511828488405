import { createReducer, on } from '@ngrx/store';
import {
    saveCommissionAgentNames,
    resetStore
} from '../actions/commission-agent-names.action';
import { commissionAgentNamesInitialState } from '../initial-state';

const _commAgentNamesReducer = createReducer(
    commissionAgentNamesInitialState,
    on(saveCommissionAgentNames, (state, { payload }) => ({ ...state, ...payload })),
    on(resetStore, (state) => ({}))
);

export function commissionAgentNamesReducer(state = commissionAgentNamesInitialState, action) {
    return _commAgentNamesReducer(state, action);
}