<div class="common-dialog-wrapper" *ngIf="conditionCheck(data,'notterminatedUser')">
    <div class="close-image">
        <img *ngIf="data.isCloseImageVisible" src="{{data?.closeImg}}" (click)="close()" alt="{{data?.logoAltText}}" />
    </div>
    <div class="image-wrapper">
        <img *ngIf="data.isShowIcon" src="{{data?.icon}}" alt="{{data?.iconText}}" />
    </div>
    <div *ngIf="data.header" class="header">
        {{data?.header}}
        <ng-container *ngIf="data.emailLink">
            <span (click)="openOutlook(data.emailLink)" class="emailLink">{{data.emailLink}}</span>
        </ng-container>
    </div>
    <div *ngIf="data.subHeader" class="sub-header">{{data?.subHeader}}</div>
    <div *ngIf="data.content" class="content">{{data?.content}}</div>
    <div class="button-wrapper">
        <div class="btn-container">
            <crux-button *ngIf="data.primaryButtonText" class="primary-btn" mode="primary" (click)="close()">
                {{data?.primaryButtonText}}
            </crux-button>
            <crux-button *ngIf="data.SecondaryButtonText" class="secondary-btn" mode="primary"
                [ngClass]="(data?.cssClass !== undefined && data?.cssClass !== null && data.cssClass !== '') ? data.cssClass : null"
                (click)="delete()"> {{data?.SecondaryButtonText}}
            </crux-button>
        </div>
    </div>
</div>
<div class="common-dialog-wrapper terminatedWrapper" *ngIf="conditionCheck(data,'terminatedUser')">
    <div class="close-image">
        <img *ngIf="data.isCloseImageVisible" src="{{data?.closeImg}}" (click)="close()" alt="{{data?.logoAltText}}" />
    </div>
    <div class="image-wrapper">
        <img *ngIf="data.isShowIcon" src="{{data?.icon}}" alt="{{data?.iconText}}" />
    </div>
    <div *ngIf="data.header" class="header terminatedHeader">{{data?.header}}</div>
    <div *ngIf="data.subHeader" class="sub-header terminatedSubHeader">{{data?.subHeader}}</div>
    <div *ngIf="data.content" class="content terminatedContent">{{data?.content}}</div>
    <div class="button-wrapper">
        <div class="btn-container">
            <crux-button *ngIf="data.primaryButtonText" class="primary-btn" mode="primary" (click)="close()">
                {{data?.primaryButtonText}}
            </crux-button>
            <crux-button *ngIf="data.SecondaryButtonText" class="secondary-btn redBtn" mode="primary"
                (click)="delete()"> {{data?.SecondaryButtonText}}
            </crux-button>
        </div>
    </div>
</div>