import { createReducer, on } from '@ngrx/store';

import { proposedProductDataState } from '../initial-state';
import { saveProposedProductData, resetStore } from '../actions';

const _proposedProductDataReducer = createReducer(
    proposedProductDataState,
    on(saveProposedProductData, (state, { payload }) => ({ ...state, ...payload })),
    on(resetStore, (state) => ({}))
);

export function proposedProductDataReducer(state = proposedProductDataState, action) {
    return _proposedProductDataReducer(state, action);
}