import { createReducer, on } from '@ngrx/store';
import { 
    saveEligibilityGuidelines, 
    reset 
} from '../actions/eligibility-guidelines.actions';
import { eligibilityGuidelinesInitialState } from '../initial-state/eligibility-guidelines.state';


const _eligibilityGuidelinesReducer = createReducer(
    eligibilityGuidelinesInitialState,
    on(saveEligibilityGuidelines, (state, { payload }) => ({ ...state, ...payload })),
    on(reset, (state) => ({}))
);

export function eligibilityGuidelinesReducer(state = eligibilityGuidelinesInitialState, action) {
    return _eligibilityGuidelinesReducer(state, action);
}