import { createReducer, on } from '@ngrx/store';
import {
    saveContactDetails,
    reset
} from '../actions/contact-details.action';
import { contactDetailsInitialState } from '../initial-state';


const _contactDetailsReducer = createReducer(
    contactDetailsInitialState,
    on(saveContactDetails, (state, { payload }) => ({ ...state, ...payload })),
    on(reset, (state) => ({}))
);

export function contactDetailsReducer(state = contactDetailsInitialState, action) {
    return _contactDetailsReducer(state, action);
}