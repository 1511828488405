<div class="loading-indicator-container">
  <div class="indicator-icon">
    <img *ngIf="data && data.loadingIcon" src="{{data.loadingIcon}}" alt="{{data?.loadingText}}">
    <div *ngIf="!data || !data.loadingIcon" class="loading-image">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
  <div class="indicator-text-placeholder" *ngIf="!data || !data.loadingText">
  </div>
  <div class="indicator-text" *ngIf="data && data.loadingText">
    {{data?.loadingText}}
  </div>
  <ng-container *ngIf="data && data.subText">
    <div class="indicator-sub-text" *ngFor="let item of data.subText">
      {{item}}
    </div>
  </ng-container>
</div>