import { createReducer, on } from '@ngrx/store';

import { localChangesState } from '../initial-state';
import { saveLocalChangesDetails, reset } from '../actions';

const _localChangesReducer = createReducer(
  localChangesState,
  on(saveLocalChangesDetails, (state, { payload }) => ({ ...state, ...payload })),
  on(reset, (state) => ({}))
);

export function localChangesReducer(state = localChangesState, action) {
  return _localChangesReducer(state, action);
}
