import { createReducer, on } from '@ngrx/store';
import {
    saveEnrollmentInformation,
    reset
} from '../actions/enrollment-info.action';
import { enrollmentInformationInitialState } from '../initial-state';


const _enrollmentInformationReducer = createReducer(
    enrollmentInformationInitialState,
    on(saveEnrollmentInformation, (state, { payload }) => ({ ...state, ...payload })),
    on(reset, (state) => ({}))
);

export function enrollmentInformationReducer(state = enrollmentInformationInitialState, action) {
    return _enrollmentInformationReducer(state, action);
}