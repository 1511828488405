import { Inject, Injectable } from "@angular/core";
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  NavigationExtras
} from "@angular/router";
import { Observable, of, Subscription } from "rxjs";
import { AppContextService } from "../app.context.service";
import { catchError, map, tap } from "rxjs/operators";
import { StudioError } from "../error-handlers/studio.error";
import { environment } from "../../environments/environment";
import { HttpErrorResponse } from "@angular/common/http";
import { WINDOW } from "../services/window.service";
import { DomainsMappingService } from "../services/domains-mapping.service";
import { BaseUrlService } from "../services/base-url.service";
// import { config } from 'protractor.conf';

@Injectable()
export class AppContextGuard implements CanActivate {
  private _sectionStatusSubscription: Subscription;
  private subscriptions: {
    [key: string]: Subscription;
  } = {};
  constructor(
    private _appContext: AppContextService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _domainMappingService: DomainsMappingService,
    private _bus: BaseUrlService,
    @Inject(WINDOW) private window: Window
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    if (this._appContext.get("")) {
      return of(true);
    }
    let configPath = null;

    try {
      configPath = this._getConfigPath(
        this._domainMappingService.getMapping(),
        this.window.location
      );
    } catch (e) {
      // TODO check if loading icon is required or not
    }
    if (!configPath) {
      return of(false);
    }
    return this._appContext.load(configPath).pipe(
      tap(() => {
        // TODO check if any loading icon is required or not
      }),
      map(appContext => !!appContext),
      catchError(error => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 404) {
            this._router.navigate([environment.uri.pageNotFound]);
          }
        }

        return of(false);
      })
    );
  }

  _getConfigPath(domainsMapping: any, location: Location): string {
    const domainName = location.host;
    let domainConfig = domainsMapping[domainName];
    if (!domainConfig) {
      domainConfig = domainsMapping["*"];
      if (!domainConfig) {
        throw new StudioError(
          `Can't find configuration for domain ${domainName}`
        );
      }
    }
    let selectedLanguage = location.pathname.split("/")[1];

    let defaultPath;
    if (selectedLanguage === "en-US") {
      defaultPath = domainConfig.find(path => path.enUS === true);
    } else if (selectedLanguage === "es-US") {
      defaultPath = domainConfig.find(path => path.esUS === true);
    } else {
      defaultPath = domainConfig.find(path => path.default === true);
    }

    // const defaultPath = domainConfig.find(path => path.en === true);
    if (!defaultPath) {
      throw new StudioError(`Can't find default path for domain ${domainName}`);
    }

    const locationPathBaseHref = location.pathname
      .split("/")
      .slice(0, 6)
      .join("/");

    if (locationPathBaseHref === defaultPath.baseHref) {
      const routePath = location.pathname
        .split("/")
        .slice(6, location.pathname.split("/").length)
        .join("/");

      this._bus.baseUri = "";

      this._router.navigate([routePath]);
      return null;
    }

    if (location.pathname.split("/").slice(0, 6).length < 5) {
      this._bus.baseUri = "";
      return defaultPath.baseHref;
    }

    const baseHref = domainConfig.find(
      path => path.baseHref === locationPathBaseHref
    );
    if (!baseHref) {
      this._router.navigate([environment.uri.pageNotFound]);
      throw new StudioError(`Can't find path ${locationPathBaseHref}`);
    }
    this._bus.baseUri = locationPathBaseHref;

    return locationPathBaseHref;
  }
}
