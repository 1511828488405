import { createReducer, on } from '@ngrx/store';
import {
    saveagentNames,
    resetStore
} from '../actions/agent-names.action';
import { agentNamesInitialState } from '../initial-state';

const _agentNamesReducer = createReducer(
    agentNamesInitialState,
    on(saveagentNames, (state, { payload }) => ({ ...state, ...payload })),
    on(resetStore, (state) => ({}))
);

export function agentNamesReducer(state = agentNamesInitialState, action) {
    return _agentNamesReducer(state, action);
}