import { createReducer, on } from '@ngrx/store';
import { reset, saveProductRestrictions } from '../actions/product-restrictions.action';
import { productRestritionsCodeInitialState } from '../initial-state/product-restrictions.state';

const __productsRestrictionReducer = createReducer(
  productRestritionsCodeInitialState,
  on(saveProductRestrictions, (state, { payload }) => ({ ...state, ...payload })),
  on(reset, (state) => ({}))
);

export function productsRestrictionReducer(state = productRestritionsCodeInitialState, action) {
  return __productsRestrictionReducer(state, action);
}
