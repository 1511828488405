import { createReducer, on } from '@ngrx/store';
import {
    saveformsDataDetails,
    reset
} from '../actions/forms-data-details.action';
import { formsDataDetailsInitialState } from '../initial-state';


const _formsDataDetailsReducer = createReducer(
    formsDataDetailsInitialState,
    on(saveformsDataDetails, (state, { payload }) => ({ ...state, ...payload })),
    on(reset, (state) => ({}))
);

export function formsDataDetailsReducer(state = formsDataDetailsInitialState, action) {
    return _formsDataDetailsReducer(state, action);
}