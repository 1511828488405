import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingIndicatorService } from '../../../services';

import { MsalService, BroadcastService } from "@azure/msal-angular";
import { InteractionRequiredAuthError } from 'msal/lib-commonjs/error/InteractionRequiredAuthError';
import { HttpClient } from '@angular/common/http';
import { Globals } from 'src/app/globals';


@Component({
    selector: "app-login-callback",
    templateUrl: "./login-callback.component.html"
})
export class LoginCallbackComponent implements OnInit {
    isUnqork;
    constructor(
        private authService: MsalService,
        private _router: Router,
        private _http: HttpClient,
        private globals: Globals
    ) {
        this.isUnqork = localStorage.getItem('isFromUnqork');
    }

    ngOnInit() {
        if(this.isUnqork == '1') {
            const accountDetails = this.globals.getAccountDetails();
                    if (accountDetails) {
                        this._router.navigate(["dashboard"]);
                    }
        }
        else {
            if (
                this.authService.getAccount() &&
                !this.authService.isCallback(window.location.hash)
            ) {
                const accountDetails = this.authService.getAccount();
                if (accountDetails) {
                    this._router.navigate(["dashboard"]);
                }
            } else {
                this.authService.loginRedirect();
            }
        }
        // if (
        //     this.authService.getAccount() &&
        //     !this.authService.isCallback(window.location.hash)
        // ) {
        //     const accountDetails = this.authService.getAccount();
        //     if (accountDetails) {
        //         this._router.navigate(["dashboard"]);
        //     }
        // } else {
        //     this.authService.loginRedirect();
        // }
    }

}
