import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { throwError, Observable, BehaviorSubject, of } from 'rxjs';
import {
  catchError,
  filter,
  take,
  switchMap,
  finalize,
  map,
  concatMap,
  timeout,
} from 'rxjs/operators';
import { AppContextService } from '../app.context.service';
import { environment } from '../../environments/environment';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { FormService } from '../services/form.services';
import { saveTokenDetails } from '../state/actions';
import { LoadingIndicatorService } from '../services/loading-indicator.service';

@Injectable()
export class HttpHeaderInterceptor implements HttpInterceptor {
  private isRefreshTokenCompleted = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  authTokenVar: any;
  _storeStatusSubscription: Subscription;
  headerToken: any;
  waitFlag = false;
  iFrameData = {};
  pdfHeaderToken: any;
  activeRequests: number = 0;
  trainingMode = false;

  constructor(
    private _store: Store<any>,
    private _services: FormService,
    private _loaderService: LoadingIndicatorService
  ) {
    this._storeStatusSubscription = this._store.select("tokenDetails").subscribe((state: any) => {
      if (state) {
        const tokenDetails = state.tokenData;
        this.headerToken = {
          'Content-Type': 'application/json',
          Authorization: tokenDetails,
          apiVersion: environment.endPoints.apiVerson,
          'Ocp-Apim-Subscription-Key': environment.endPoints.OcpApimSubscriptionKey,
        };
        this.pdfHeaderToken = {
          'Content-Type': 'application/pdf',
          Authorization: tokenDetails,
          apiVersion: environment.endPoints.apiVerson,
          'Ocp-Apim-Subscription-Key': environment.endPoints.OcpApimSubscriptionKey,
        };
      }
    });
    this._store.select('dashboardDetails').subscribe(state => {
      if(state && state.appMode === 'training') {
        this.trainingMode = true;
      } else {
        this.trainingMode = false;
      }
    })
  }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<any> {
      let authReq = req.clone({
        setHeaders: {},
      });
      if (
        !req.headers.get('App_Key') &&
        !req.url.includes('.json')
      ) {
        authReq = req.clone({
          setHeaders: this.headerToken,
        });
      }
      
      let spinnerSubscription: Subscription
      if(req.body && req.body.hasOwnProperty('enableLoader')){
        delete req.body['enableLoader'];
        spinnerSubscription = this._loaderService.spinner$.subscribe();
      }else if(req.body && req.body.hasOwnProperty('showLoader')){
        delete req.body['showLoader'];
      }else if(req.method === "GET" && (!req.url.includes('sockjs-node') && !req.url.includes("app-config.json") && !req.url.includes("selerix-transformation-businuessDetailsStore.json"))){
        if (!(req.headers.get('loader') && req.headers.get('loader') === 'notrequired')) {
          spinnerSubscription = this._loaderService.spinner$.subscribe();
        }
      }
      
    if (this.trainingMode && !this.checkAllowRequest(req)) {
      return of(new HttpResponse({
        status: 200,
        statusText: "OK",
        body: {
          Code: "0000",
          Master_Application_Status__c: "Submitted",
          Message: "Successful",
          RFP_Status__c: "Submitted",
          GCAP_app_metadata__c: null,
          records: [
            {
              "attributes": {
                "type": "",
                "url": ""
              },
              "Title": "Dummy.pdf",
              "PathOnClient": "{\"component\":\"standardCommissionForm\",\"size\":91374}",
              "ContentLocation": "S",
              "VersionData": ""
            },{
              "attributes": {
                "type": "",
                "url": ""
              },
              "Title": "Dummy.pdf",
              "PathOnClient": "{\"component\":\"HDB\",\"size\":91374}",
              "ContentLocation": "S",
              "VersionData": ""
            },
            {
              "attributes": {
                "type": "",
                "url": ""
              },
              "Title": "Dummy.pdf",
              "PathOnClient": "{\"component\":\"hrPayrollEfulfillment\",\"size\":91374}",
              "ContentLocation": "S",
              "VersionData": ""
            },
            {
              "attributes": {
                "type": "",
                "url": ""
              },
              "Title": "Dummy.pdf",
              "PathOnClient": "{\"component\":\"LBT\",\"size\":91374}",
              "ContentLocation": "S",
              "VersionData": ""
            },
            {
              "attributes": {
                "type": "",
                "url": ""
              },
              "Title": "Dummy.pdf",
              "PathOnClient": "{\"component\":\"accidentChampion\",\"size\":91374}",
              "ContentLocation": "S",
              "VersionData": ""
            },
            {
              "attributes": {
                "type": "",
                "url": ""
              },
              "Title": "Dummy.pdf",
              "PathOnClient": "{\"component\":\"criticalIllness\",\"size\":91374}",
              "ContentLocation": "S",
              "VersionData": ""
            },
            {
              "attributes": {
                "type": "",
                "url": ""
              },
              "Title": "Dummy.pdf",
              "PathOnClient": "{\"component\":\"disabilityChampion\",\"size\":91374}",
              "ContentLocation": "S",
              "VersionData": ""
            },
            {
              "attributes": {
                "type": "",
                "url": ""
              },
              "Title": "Dummy.pdf",
              "PathOnClient": "{\"component\":\"GHIcustom\",\"size\":91374}",
              "ContentLocation": "S",
              "VersionData": ""
            },
            {
              "attributes": {
                "type": "",
                "url": ""
              },
              "Title": "Dummy.pdf",
              "PathOnClient": "{\"component\":\"GHI\",\"size\":91374}",
              "ContentLocation": "S",
              "VersionData": ""
            },
            {
              "attributes": {
                "type": "",
                "url": ""
              },
              "Title": "Dummy.pdf",
              "PathOnClient": "{\"component\":\"soldProposal\",\"size\":91374}",
              "ContentLocation": "S",
              "VersionData": ""
            },
            {
              "attributes": {
                "type": "",
                "url": ""
              },
              "Title": "Dummy.pdf",
              "PathOnClient": "{\"component\":\"union\",\"size\":91374}",
              "ContentLocation": "S",
              "VersionData": ""
            },
            {
              "attributes": {
                "type": "",
                "url": ""
              },
              "Title": "Dummy.pdf",
              "PathOnClient": "{\"component\":\"association\",\"size\":91374}",
              "ContentLocation": "S",
              "VersionData": ""
            },
            {
              "attributes": {
                "type": "",
                "url": ""
              },
              "Title": "Dummy.pdf",
              "PathOnClient": "{\"component\":\"tribalEntity\",\"size\":91374}",
              "ContentLocation": "S",
              "VersionData": ""
            },
            {
              "attributes": {
                "type": "",
                "url": ""
              },
              "Title": "Dummy.pdf",
              "PathOnClient": "{\"component\":\"applicationReview\",\"size\":91374}",
              "ContentLocation": "S",
              "VersionData": ""
            },
            {
              "attributes": {
                "type": "",
                "url": ""
              },
              "Title": "Dummy.pdf",
              "PathOnClient": "{\"component\":\"rfpapplicationReview\",\"size\":91374}",
              "ContentLocation": "S",
              "VersionData": ""
            }
          ]
        },
        url: ""
      })).pipe(finalize(() => {
        if (spinnerSubscription) {
          setTimeout(() => {
            spinnerSubscription.unsubscribe();
          }, 0);
        }
      }));
    } else {
        if(req.body && req.body.hasOwnProperty('allowInTraining')){
          delete req.body['allowInTraining'];
        }
          return next.handle(authReq).pipe(
            map((event: HttpEvent<any>) => {
              return event;
            }),
            catchError((error: HttpErrorResponse) => {
              if (error && error.status === 401) {
                // 401 errors are most likely going to be because we have an expired token that we need to refresh.
                if (this.isRefreshTokenCompleted) {
                  // If isRefreshTokenCompleted is true, we will wait until refreshTokenSubject has a non-null value
                  // which means the new token is ready and we can retry the request again
                  return this.refreshTokenSubject.pipe(
                    filter((result) => result !== null),
                    take(1),
                    switchMap(() => next.handle(this.addAuthenticationToken(authReq)))
                  );
                } else {
                  this.isRefreshTokenCompleted = true;
      
                  // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
                  this.refreshTokenSubject.next(null);
      
                  return this.refreshAccessToken().pipe(
                    concatMap((response: any) => {
                      this.iFrameData['apiToken'] = response.token_type + ' ' + response.access_token;
                      const tokenData = response.token_type + ' ' + response.access_token;
                      this._store.dispatch(saveTokenDetails({ payload: { tokenData } }));
                      this.refreshTokenSubject.next(response);
      
                      return next.handle(this.addAuthenticationToken(authReq));
                    }),
                    // When the call to refreshToken completes we reset the isRefreshTokenCompleted to false
                    // for the next time the token needs to be refreshed
                    finalize(() => (this.isRefreshTokenCompleted = false))
                  );
                }
              } else {
                return throwError(error);
              }
            }),
            finalize(() => {
              if(spinnerSubscription){
                setTimeout(() => {
                  spinnerSubscription.unsubscribe();
                }, 0);
              }
            })
          );
        }   
  }
  /**
   * Refresh Token API Call
   */
  private refreshAccessToken() {
    return this._services.getToken().pipe(take(1)).pipe(timeout(120000));
  }
  /**
   * Adding a active token to the header
   * @param req - HTTP Request
   */
  private addAuthenticationToken(req: HttpRequest<any>): HttpRequest<any> {
    let authReq = req.clone({
      setHeaders: {},
    });
    if (
      !req.headers.get('skip') &&
      !req.headers.get('Access-Control-Allow-Origin')
    ) {
      authReq = req.clone({
        setHeaders: this.headerToken,
      });
    }
    return authReq;
  }

  checkAllowRequest(req: HttpRequest<any>): boolean {
    if(req.url.includes(".json")) {
      return true;
    } else if (req.url === environment.csiUrl.tokenAPI) {
      return true;
    } else if (req.method.toLowerCase() === 'get') {
      return true;
    } else if (req?.body.allowInTraining === true) {
      return true
    } else if (req?.body.HttpMethod === 'GET' && !req?.body.SubUri.includes("undefined")) {
      return true;
    }
  }
}


