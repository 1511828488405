import { createReducer, on } from '@ngrx/store';
import {
    saveBrokerNames,
    resetStore
} from '../actions/broker-names.action';
import { brokerNamesInitialState } from '../initial-state';

const _brokerNamesReducer = createReducer(
    brokerNamesInitialState,
    on(saveBrokerNames, (state, { payload }) => ({ ...state, ...payload })),
    on(resetStore, (state) => ({}))
);

export function brokerNamesReducer(state = brokerNamesInitialState, action) {
    return _brokerNamesReducer(state, action);
}