import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FlexLayoutModule } from '@angular/flex-layout';
import { MultiLoadingIndicatorComponent } from "./multi-loading-indicator.component";
import { FormValidationService } from "src/app/services/form-validation.service";

@NgModule({
  declarations: [MultiLoadingIndicatorComponent],
  imports: [CommonModule,
    FlexLayoutModule],
  exports: [MultiLoadingIndicatorComponent],
  entryComponents: [MultiLoadingIndicatorComponent]
})
export class MultiLoadingIndicatorModule {}
