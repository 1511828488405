import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoadingIndicatorService } from 'src/app/services/loading-indicator.service';

@Component({
  selector: 'app-common-dialog',
  templateUrl: './common-dialog.component.html',
  styleUrls: ['./common-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CommonDialogComponent implements OnInit {

  constructor(
    private _loaderService: LoadingIndicatorService,
    public dialogRef: MatDialogRef<CommonDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit(): void {
  }
  conditionCheck(e,str) {
    if(str === 'terminatedUser'){
      return e && e.reason && e.reason ==='terminatedUser';
    }else if(str === 'notterminatedUser'){
      return e && e.reason && e.reason !== 'terminatedUser' || e && !e.reason;
    }
  }
  close(): void {
    this.dialogRef.close();
      this._loaderService.closeAllLoadingIndicator();
  }

  delete(): any {
    this.dialogRef.close(this.data);
  }
  
  openOutlook(emailLink:any){
    // mail = document.createElement("a");
  // mail.href = "mailto:abc@abc.com?subject=files&body=Hi";
  // mail.click();
    let mailText = `mailto:${emailLink}`; 
    window.location.href = mailText;
  }
}
