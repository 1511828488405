import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class PreventBackButtonService {
    flag: boolean;
    constructor() {
    }
    getBackClicked() {
        return this.flag;
    }
    setBackClicked(flg: boolean) {
        this.flag = flg;
    }
}
