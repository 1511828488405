import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoadingIndicatorComponent } from '../widgets/shared/loading-indicator/loading-indicator.component';
import { defer, NEVER } from 'rxjs';
import { finalize, share } from 'rxjs/operators';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class LoadingIndicatorService {
    dialogRef: any;
    activePage: any;

    constructor(public dialog: MatDialog, private router: Router) {
        this.router.events.subscribe(this.navigationInterceptor.bind(this));
    }
    openLoadingIndicator(data) {
        // wont open dialog if one is already opened
        //if (this.dialog.openDialogs && this.dialog.openDialogs.length === 0) {
            this.dialogRef = this.dialog.open(LoadingIndicatorComponent, {
                height: 'auto',
                data: data,
                disableClose: true
            });
        //}
    }
    closeLoadingIndicator() {
        if (this.dialogRef) {
            this.dialogRef.close();
        }
    }

    closeAllLoadingIndicator() {
        // if (this.dialogRef) {
        //     this.dialogRef.closeAll();
        // }
        this.dialog.openDialogs.forEach(e => {
            if(e.componentInstance.data.fromDisable == false){
                
            }else if(e.componentInstance.data.rmoveRow == true){
                setTimeout(() => {
                    e.close();
                }, 3000);
            }else{
                e.close();
            }
        });
    }

    public readonly spinner$ = defer(() => {
        this.openLoadingIndicator({});
        return NEVER.pipe(
          finalize(() => {
            this.closeLoadingIndicator();
          })
        );
      }).pipe(share());
    
      private navigationInterceptor(event: RouterEvent) {
        if (event instanceof NavigationStart && event.url !== this.activePage) {
            this.activePage = event.url;
            this.openLoadingIndicator({});
        }
        if (event instanceof NavigationEnd) {
            this.activePage = null;
            this.closeAllLoadingIndicator();
        }
    
        // Set loading state to false in both of the below events to hide the spinner in case a request fails
        /* if (event instanceof NavigationCancel) {
            this.closeAllLoadingIndicator();
        }
        if (event instanceof NavigationError) {
            this.closeAllLoadingIndicator();
        } */
    }
}
