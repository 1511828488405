import { createReducer, on } from '@ngrx/store';
import { reset, saveDashboardDetails,  } from '../actions/dashboard-details.action';
import { dashboardDetailsInitialState } from '../initial-state/dashboard-details.state';



const _dashboardDetailsReducer = createReducer(
    dashboardDetailsInitialState,
    on(saveDashboardDetails, (state, { payload }) => ({ ...state, ...payload })),
    on(reset, (state) => ({}))
);

export function dashboardDetailsReducer(state = dashboardDetailsInitialState, action) {
    return _dashboardDetailsReducer(state, action);
}